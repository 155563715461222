<script>
  import { eventBus } from '@/main.js'
  /** A Reusable TextRow component that can be used in content builder and standalone templates */

  export default {
    name: 'NewBuilderTwoColumnsText',
    props: {
      /** The heading for the block, if defined */
      leftText: {
        type: String,
        default: '',
      },
      rightText: {
        type: String,
        default: '',
      },
      removePadding: {
        type: Boolean,
        default: false,
      },
      link: {
        type: Object,
        default: () => ({}),
      },
      linkText: {
        type: String,
        default: '',
      },
      textAlign: {
        type: Boolean,
        default: false,
      },
      mailOrLink: {
        type: Boolean,
        default: false,
      },
      mailtoLink: {
        type: String,
        default: '',
      },
      currentPageUrl: {
        type: String,
        default: '',
      },
      scrollToForm:{
        type: Boolean,
        default: false,
      },
    },
    computed: {
      resizedImage() {
        return this.bannerImage.urls[0]
      },
    },
    mounted() {
      eventBus.$on('sendFormId', (data) => {
        this.formId = data
      })
      const hash = window.location.hash
      if (hash === '#scrollToForm') {
        this.showFormOnScroll()
      }
    },
    methods: {
      showFormOnScroll() {
        const element = document.getElementById('tradeAccountForm')
        const noticeElement = document.getElementById('notice')
        const notHeight = noticeElement ? noticeElement.offsetHeight : 0
        const headerHeight = document.getElementById('ref-header').offsetHeight
        this.showForm = true
        if (element) {
          eventBus.$emit('showFormOnScroll', this.showForm)
          setTimeout(() => {
            const sum = notHeight + headerHeight - 20
            if (element) {
              const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - sum
              window.scrollTo({ top: elementPosition, behavior: 'smooth' })
            }
          }, 200)
        } else {
          window.location.href = '/trade#scrollToForm'
        }
      },
    },
  }
</script>

<template>
  <div>
    <div class="twoColumnsSectionWrapper" :class="`${removePadding ? 'noPadding' : ''}`">
      <div class="w-full flex flex-row" :class="{ 'justify-end': !textAlign }">
        <div
          class="w-full lg:w-8/12 lg:flex lg:flex-row lg:space-x-16"
          :class="{ 'lg:pl-4': !textAlign }"
        >
          <div class="w-full mb-6 lg:mb-0 lg:w-6/12 leftTextWrapper">
            <div v-if="leftText" v-html="leftText"></div>
          </div>
          <div class="w-full lg:w-6/12">
            <div v-if="rightText" class="mb-10" v-html="rightText"></div>
            <div v-if="linkText">
              <a
                v-if="!mailOrLink"
                class="font-semibold text-sm block text-center button-height md:px-26 md:inline-block | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
                href="#"
                @click.prevent="showFormOnScroll"
                >{{ linkText }}</a>
              <a
                v-else
                class="font-semibold text-sm block text-center button-height md:px-26 md:inline-block | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
                :href="`mailto:${mailtoLink}`"
                >{{ linkText }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .twoColumnsSectionWrapper {
    padding: 100px 0;

    &.noPadding {
      padding: 0;
    }
    @media (max-width: 1024px) {
      padding: 60px 0;

      &.noPadding {
        padding: 0;
      }
    }
  }

  >>> .leftTextWrapper {
    h2,
    h3,
    h4 {
      display: block;
      margin: 0 0 38px 0;
      font-size: 36px;
      font-weight: 500;
      line-height: 43px;
    }

    p {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    @media (max-width: 1024px) {
      h2,
      h3,
      h4 {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
</style>
