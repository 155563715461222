<script>
  export default {
    name: 'ProductStory',
    props: {
      label: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      image: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      logo: {
        type:String,
        default:'',
        required: false
      },
    },
  }
</script>

<template>
  <section class="pt-6 xl:grid xl:grid-cols-12 xl:gap-6">
    <figure 
      v-if="logo" 
      class="responsive-image mb-2"
      :class="`${ label === 'Designer' ? 'designer--logo' : ''}`"
    >
      <img :src="logo" alt="">
    </figure>

    <div class="xl:col-span-12">
      <h3 class="mb-2 xl:mb-4">{{ title }}</h3>
      <div class="text-gray-600 leading-snug wysiwyg">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
  .v-lazy-image {
    opacity: 0;
    transition: opacity 0.7s;
  }

  .responsive-image {
    width: 110px;
    height: 110px;
    border: 1px solid #DFDFDF;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.designer--logo {
      padding: 0;
      border-color: transparent;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .v-lazy-image-loaded {
    opacity: 1;
  }
</style>
