<script>
  /** A Reusable TextRow component that can be used in content builder and standalone templates */

  export default {
    name: 'NewBuilderTopBannerWithTitle',
    props: {
      /** The heading for the block, if defined */
      bannerTitle: {
        type: String,
        required: true
      },
      bannerImage:{
        type: Object,
        required:true,
        default: () => ({})
      }
    },
    computed: {
      resizedImage() {
        return this.bannerImage.urls[0]
      }
    }
  }
</script>

<template>
  <div>
    <div
        v-if="bannerTitle"
        class="w-full pt-14 lg:pt-20"
    >
        <div class="w-full mb-8 lg:mb-12">
            <h1 v-if="bannerTitle">{{ bannerTitle }}</h1>
        </div>
        <div class="w-full imageWrapper">
            <img 
              :src="resizedImage" 
              :alt="bannerImage.alt" 
            >
        </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  h1 {
    font-size: 60px;
    line-height: 72px;
  }
  .imageWrapper {
    width: 100%;
    height: 600px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
  @media(max-width:992px) {
    h1 {
      font-size: 45px;
      line-height: 50px;
    }
    .imageWrapper {
      height: 450px;;
    }
  }
  @media(max-width:767px) {
    .imageWrapper {
      height: 320px;
    }
  }
</style>