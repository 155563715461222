<script>
  import BaseFilterButton from '@/components/BaseFilterButton.vue'
  import IconCheckbox from '@/images/icons/checked.svg'

  /** Reusable checkbox component meant to be used in filter bars. Emits a 'change' event when checked/unchecked with its current state */

  export default {
    name: 'BaseFilterCheckbox',

    components: {
      BaseFilterButton,
      IconCheckbox,
    },

    props: {
      /** The checkbox's label */
      label: {
        type: String,
        required: true,
      },
      /** Whether the checkbox is checked */
      checked: {
        type: Boolean,
        default: false,
      },
      /** Whether to display a gray border around the component */
      border: {
        type: Boolean,
        default: false,
      },
      /** Whether the checkbox is disabled */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** Number products available for filter */
      availableItems: {
        type: [Number, Boolean],
        default: 0,
      },
    },

    data() {
      return {
        isChecked: this.checked,
      }
    },

    watch: {
      /** Watches the checked prop to set the internal isChecked data */
      checked() {
        this.isChecked = this.checked
      },
    },

    methods: {
      /** When the checkbox is checked or unchecked this method emits its new state */
      handleChange() {
        this.$emit('change', this.isChecked)
      },
    },
  }
</script>

<template>
  <BaseFilterButton
    tag="label"
    :label="label"
    :available-items="availableItems"
    :border="border"
    :emphasis="isChecked"
    :disabled="disabled"
    text-align="end"
    :class="`flex-row-reverse pl-0 font-normal text-base max-h-4 mb-3`"
  >
    <template v-slot:before>
      <input
        v-model="isChecked"
        type="checkbox"
        class="checkbox-input absolute opacity-0 pointer-events-none"
        :class="$attrs"
        :disabled="disabled"
        @change="handleChange"
      />
    </template>
    <template v-slot:after>
      <span
        class="checkbox-box mr-4 flex-shrink-0 border border-gray-600 transition-colors duration-300 font-normal"
        ><IconCheckbox
      /></span>
    </template>
  </BaseFilterButton>
</template>

<style scoped lang="postcss">
  .checkbox-box {
    width: 16px;
    height: 16px;
    overflow: hidden;

    svg {
      position: relative;
      top: -1px;
      display: none;
      height: 16px;
    }

    .checkbox-input:checked ~ & svg {
      display: block;
    }
  }
</style>
