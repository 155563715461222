<script>
  import ProductTile from '@/components/products/ProductTile'
  import BaseCarousel from '@/components/BaseCarousel'

  /** Featured Products component to display selected products from the content builder */

  export default {
    name: 'BuilderProductCarousel',

    components: {
      ProductTile,
      BaseCarousel,
    },

    props: {
      /** Tab group array */
      tabs: {
        type: Array,
        required: true,
      },
      /** Product carousel label */
      label: {
        type: String,
        default: null,
      },
      /** Product carousel anchor */
      anchor: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        loader: {
          width: 0,
          height: 0,
        },
        offsetLeft: 0,
        activeTab: null,
      }
    },

    computed: {
      seeAllLinkText() {
        return this.activeTab.label
      },
      seeAllUrl() {
        let param = null

        switch (this.activeTab.label) {
          case 'In Showroom':
            param = 'in-showroom'
            break
          case 'Get it Quick':
            param = 'quick-ship'
            break
          case 'New':
            param = 'new'
            break
          case 'Buy Now':
            param = 'buy-now'
            break
        }

        return `/catalog?filter=${param}`
      },
      /** Filter out null objects **/
      activeProducts: function () {
        return this.activeTab.products.filter(function (p) {
          if (p) {
            return p.id
          }
        })
      },
    },

    created() {
      this.activeTab = this.tabs[0]
      this.getOffsetLeft()
      window.addEventListener('resize', this.getOffsetLeft)
    },

    methods: {
      setActiveTab(tab) {
        this.activeTab = tab
      },
      getOffsetLeft() {
        const containerLeft = document.querySelector('.textRowSection').getBoundingClientRect().left
        this.offsetLeft = containerLeft.toFixed(0) - 7
      },
    },
  }
</script>

<template>
  <aside
    :id="anchor"
    class="relative w-full pt-6 || section featured-products"
    :style="{ 'padding-left': offsetLeft + 'px' }"
    :aria-label="label"
  >
    <div class="tabs pt-4">
      <!--<div class="sm:block lg:pl-2">
        <nav class="flex lg:flex-row lg:overflow-x-hidden mb-6" role="navigation">
          <BaseTabsNavSlider>
            <button
              v-for="tab in tabs"
              :key="tab.label"
              class="tabs__btn sm:text-md lg:text-l focus:outline-none text-left"
              :class="[
                tab.label === activeTab.label
                  ? 'tabs__btn--active'
                  : '',
              ]"
              role="button"
              @click.prevent="setActiveTab(tab)"
            >
              {{ tab.label }}
            </button>
          </BaseTabsNavSlider>
        </nav>
      </div>-->
      <div role="tabpanel" class="tabs-content py-5">
        <BaseCarousel v-if="activeProducts.length" :offset-left="offsetLeft">
          <ProductTile
            v-for="product in activeProducts"
            :key="product.id"
            :type="'carousel'"
            v-bind="product"
            :hide-price="!activeTab.showPrices"
          />
        </BaseCarousel>
      </div>
    </div>
  </aside>
</template>

<style scoped lang="postcss">
  .featured-products {
    @media (max-width: 992px) {
      padding-left: 24px !important;
    }
    @media (max-width: 768px) {
      padding-left: 16px !important;
    }
  }

  .tabs {
    &__btn {
      @apply mr-12 font-semibold leading-5 cursor-pointer uppercase tracking-wide;

      position: relative;
      width: auto !important;
      padding-right: 0;

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        content: '';
        background: #000;
        transition: all 0.25s ease-in-out;
      }

      &:hover {
        &::before {
          width: 100%;
        }
      }

      &--active {
        &::before {
          width: 100%;
        }
      }
      @screen lg {
        white-space: initial;

        @apply py-1;
      }
      @media (max-width: 767px) {
        margin-right: 1.5rem;
      }
      @media (max-width: 480px) {
        margin-right: 0.7rem;
        font-size: 12px;
      }
    }
  }

  .tabs-content {
    display: flex;
    flex-wrap: wrap;
  }

  .loader {
    top: 16px;
    right: 16px;
    left: 16px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
