<script>
  import PlusIcon from '@/images/icons/plus-sign.svg'
  import MinusIcon from '@/images/icons/minus-sign.svg'
  
  export default {
    name: 'BuilderSectionWithThreeBlocks',
    components:{
      PlusIcon,
      MinusIcon
    },  
    props: {
      title:{
        type: String,
        required: true,
        default: '',
      },
      steps:{
        type:Array,
        required: true,
        default:()=>([])
      },
      faqs:{
        type:Array,
        required: true,
        default:()=>([])
      },
    }, 
    methods:{
      toggleAccordeion(index) {
        const accordeon = this.$refs.accordeon[index]
        const accordeonContent = accordeon.nextElementSibling
        accordeon.classList.toggle('active')
        accordeonContent.classList.toggle('hidden')
      }
    }
  }
</script>

<template>
  <div>
    <h2
        v-if="title"
        class="block mb-6 text-3xl font-medium lg:text-4xl lg:mb-11"
    >
        {{ title }}
    </h2>
    <div 
        v-if="steps.length > 0"
        class="flex flex-col flex-wrap lg:flex-row -mx-5"
    >
        <div
            v-for="step in steps"
            :key="step.title"
            class="w-full mb-16 px-5 lg:w-4/12 lg:mb-0"
        >
            <h3 class="block mb-4 lg:mb-8 font-semibold">{{ step.title }}</h3>
            <div class="steps--image">
                <img :src="step.image.urls[0]" class="w-full h-full object-cover">
            </div>
        </div>
        <div class="w-full px-5 lg:w-4/12">
            <h3 class="block mb-8 font-semibold text-2xl">{{ faqs[0].title }}</h3>
            <div
                v-for="(faq, index) in faqs[0].questions"
                :key="faq.question"
                class="py-4 w-full border-b border-gray-300"
                :class="`${ index === 0 ? 'border-t' : '' }`"
            >
                <h4 
                    class="block w-full m-0 font-medium text-lg relative cursor-pointer pr-8"
                    ref="accordeon"
                    @click="toggleAccordeion(index)"
                >
                    {{ faq.question }}
                    <span>
                      <PlusIcon class="plusIcon"/>
                      <MinusIcon class="minusIcon"/>
                    </span>
                </h4>
                <div class="w-full pt-4 hidden">
                    <p class="block w-full m-0">{{ faq.answer }}</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
    h4 {
      position: relative;
      span {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      svg.plusIcon {
        display: block;
      }    
      svg.minusIcon {
        display: none;
      }
      &.active {
        svg.plusIcon {
          display: none;
        }    
        svg.minusIcon {
          display: block;
        }
      }
    }
    h3 {
      font-size: 22px;
      line-height: 26px;
    }
    >>> .steps--image {
        width: 100%;
        height: 475px;
        @media(max-width:1280px) {
            height: 350px;
        }
        @media(max-width:768px) {
            height: 300px;
        }
    }
</style>