<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import IconSliderArrow from '@/images/icons/slider-arrow.svg'

  /** A Reusable slide carousel using slots to render slides. Uses VueSlickCarousel for interactions and lazy loads images via
   * VLazyImage.
   * See: https://github.com/gs-shop/vue-slick-carousel
   * See: https://github.com/alexjoverm/v-lazy-image */

  export default {
    name: 'BaseCarousel',

    components: {
      VueSlickCarousel,
      IconSliderArrow
    },

    props:{
      offsetLeft: {
        type: Number,
        required: false,
        default: 0,
      },
    },

    data() {
      return {
        reset: 0,
        prev: false,
        next: true,
        totalSlides: 0,
        slidesCount:0,
        dragging: false,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
          dots: false,
          fade:false,
          draggable: true,
          swipe: true,
          swipeToSlide: true,
          centerMode:true,
          autoplay:false,
          infinite: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 798,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
      }
    },

    updated() {
      const track = document.querySelector('.base-carousel .slick-track')

      if (track) {
        /** Get total number of slides and go to beginning slide when component slot data changes */
        this.totalSlides = document.querySelector('.base-carousel .slick-track').childElementCount
      }

      this.$refs.carousel.goTo(0)
    },
    created() {
      window.addEventListener('resize', this.handleResize)
    },
    mounted() {
      const track = document.querySelector('.base-carousel .slick-track')
      this.slidesCount = this.$slots.default.length
      if (track) {
        /** Get total number of slides */
        this.totalSlides = document.querySelector('.base-carousel .slick-track').childElementCount
      }

      this.handleResize()
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      /** Go to previous slide */
      showPrev() {
        this.$refs.carousel.prev()
        this.$refs.prev.blur()
      },
      /** Go to next slide */
      showNext() {
        this.$refs.carousel.next()
        this.$refs.next.blur()
      },
      /** shows or hides arows */
      beforeSlideChange(oldSlideIndex, newSlideIndex) {
        const slideRatio = this.totalSlides / newSlideIndex

        if (slideRatio < 1 || slideRatio >= 2) {
          this.next = true
          this.prev = true
        }

        if (slideRatio > 1 && slideRatio < 2) {
          this.next = false
          this.prev = true
        }

        if (newSlideIndex === 0) {
          this.prev = false
        }
      },
      handleResize() {
        const deviceWidth = window.innerWidth
        const numSlidesPerDeviceType = this.getDeviceNumSlides(deviceWidth)

        if (this.totalSlides <= numSlidesPerDeviceType) {
          this.next = false
        } else {
          this.next = true
        }
      },
      getDeviceNumSlides(deviceWidth) {
        if (deviceWidth < 466) {
          return 2
        }

        if (deviceWidth > 465 && deviceWidth < 1009) {
          return 3
        }

        return 4
      },
    },
  }
</script>

<template>
  <div class="base-carousel relative w-full">
    <div class="overflow-hidden">
      <VueSlickCarousel
        ref="carousel"
        :class="`${ slidesCount > 4 ? '' : 'no--center' }`"
        :key="reset"
        v-bind="settings"
        @beforeChange="beforeSlideChange"
      >
        <slot></slot>
      </VueSlickCarousel>
    </div>
    <div 
      v-if="slidesCount > 4" 
      class="arrows--wrapper"
      :style="{ 'margin-right': (offsetLeft + 7) + 'px' }"
    >
      <button 
        ref="prev" 
        class="slide-ctrl slide-ctrl--prev" 
        :class="{ 'arrow--disabled': prev === false }"
        @click="showPrev"
      >
        <IconSliderArrow />
      </button>
      <button 
        ref="next" 
        class="slide-ctrl slide-ctrl--next"
        :class="{ 'arrow--disabled': next === false }" 
        @click="showNext"
      >
        <IconSliderArrow />
      </button>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .arrows--wrapper {
    position: absolute;
    top: -40px;
    right: 0;
    width: 110px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media(max-width:992px) {
      margin-right:24px !important;
    }
    @media(max-width:767px) {
      margin-right:0 !important;
      right: auto;
      left: 0;
    }
  }
  .base-carousel {
    &.moreProductsCarousel {
      & >>> .slick-slider {
        .slick-list {
          padding: 0 !important;
        }
      }
    }
    & >>> .slick-slider {
      overflow-x: visible;

      .slick-arrow {
        position: absolute;
        left: -9999px;
      }

      .slick-list {
        margin: 0 -13px;
        padding: 0 200px 0 0 !important;
        @media(max-width:992px) {
          margin: 0 -8px;
        }
        .slick-track {
          .slick-slide {
            box-sizing: border-box;
            padding: 0 20px;
            @media(max-width:992px) {
              padding: 0 8px;
            }
          }
        }
      }
    }
  }

  .slide-ctrl {
    display:block;
    width: 41px;
    height: 9px;
    overflow: hidden;
    opacity: 1;
    transition: all 300ms;
    outline: none !important;
    border: none !important;
    &.arrow--disabled {
      opacity: 1;
    }
    @media(max-width:767px) {
      &.arrow--disabled {
        display: block;
      }
    }

    span {
      position: absolute;
      left: -9999px;
    }

    svg {
      display: block;
      width: 100%;
      height: 9px;
      fill: #000;
      transition: fill 300ms;
    }
  }

  .slide-ctrl--next {
    transform: rotate(180deg);
  }

  @screen lg {
    .slide-ctrl {
      opacity: 1;
    }
  }
</style>
