<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseColumnList from '@/components/BaseColumnList'
  import BaseColumnListLoader from '@/components/BaseColumnListLoader'
  import PreviewElement from '@/components/PreviewElement'

  const { mapGetters } = createNamespacedHelpers('brands')

  /** A list of all brands grouped by name or country. Makes use of the base column list and column
   * list loader components */

  export default {
    name: 'BrandList',

    components: {
      BaseColumnList,
      BaseColumnListLoader,
      PreviewElement,
    },

    props: {
      setLettersSize: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        loader: {
          width: 0,
          height: 0,
        },
        brandItem: {},
      }
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getIsLoading', 'getStructuredItems']),
      /** Returns the loaders emitted height */
      loaderHeight: function () {
        return this.loader.height
      },
      /** Create the loader CSS style height string */
      loaderStyles: function () {
        return 'height:' + this.loaderHeight + 'px;'
      },
    },

    methods: {
      /** Sets the loader components height */
      setLoaderHeight(loader) {
        this.loader.height = loader.height
      },
      previewItem(item) {
        this.brandItem = item
      },
    },
  }
</script>

<template>
  <div
    class="relative flex flex-wrap flex-row"
    :style="getIsLoading ? 'height: ' + (loaderHeight + 48) + 'px' : ''"
  >
    <transition name="fade">
      <div v-if="getIsLoading" class="absolute z-10 || loader" :style="loaderStyles">
        <BaseColumnListLoader @resize="setLoaderHeight" />
      </div>
    </transition>

    <transition name="fade">
      <BaseColumnList
        v-if="!getIsLoading"
        @previewItem="previewItem"
        :list="getStructuredItems"
        :item-label="'title'"
        :item-count="'products'"
        :count-label="'products'"
        :setLettersSize="setLettersSize"
        class="w-full lg:w-9/12"
        :type="'brand'"
      />
    </transition>

    <transition name="fade">
      <PreviewElement v-if="Object.keys(brandItem).length > 0" :brandItem="brandItem" />
    </transition>
  </div>
</template>

<style scoped lang="postcss">
  .previewBrandInner {
    opacity: 0;
    transition: all 0.35s ease-in-out;

    &.active {
      opacity: 1;
    }
  }

  .previewBrand {
    &.fixed {
      right: 0;
      z-index: 999;
      width: calc(25% + 12px);
      overflow-y: auto;
    }

    &.absolute {
      top: 0;
      right: 0;
    }
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .loader {
    top: 16px;
    right: 16px;
    left: 16px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .loader {
      top: 24px;
      right: 24px;
      left: 24px;
    }
  }
</style>
