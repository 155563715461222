<script>
  import { eventBus } from '@/main.js'
  /** A Reusable TextRow component that can be used in content builder and standalone templates */

  export default {
    name: 'BuilderTextLeftImageRight',
    props: {
      /** The heading for the block, if defined */
      text: {
        type: String,
        required: true,
      },
      linkText: {
        type: String,
        required: true,
      },
      url: {
        type: Object,
        required: true,
        default: () => ({}),
      },
      image: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
    data() {
      return {
        showForm: false,
        formId: '',
      }
    },
    computed: {
      resizedImage() {
        return this.image.urls[0]
      },
    },
    mounted() {
      eventBus.$on('sendFormId', (data) => {
        this.formId = data
      })
    },
    methods: {
      showFormOnScroll() {
        this.showForm = true
        eventBus.$emit('showFormOnScroll', this.showForm)
        setTimeout(() => {
          const element = document.getElementById('tradeAccountForm')
          const noticeElement = document.getElementById('notice')
          const notHeight = noticeElement ? noticeElement.offsetHeight : 0
          const headerHeight = document.getElementById('ref-header').offsetHeight
          const sum = notHeight + headerHeight - 20
          if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - sum
            window.scrollTo({ top: elementPosition, behavior: 'smooth' })
          }
        }, 200)
      },
    },
  }
</script>

<template>
  <div>
    <div>
      <div class="w-full flex flex-col md:flex-row pt-10 pb-10 lg:pt-20 lg:pb-14">
        <div class="w-full lg:flex space-x-0 lg:flex-row">
          <div class="w-full lg:w-1/2 lg:pr-5 mb-12 lg:mb-0">
            <h2 class="hidden mb-4">This is test2222222!!!</h2>
            <div class="textSection mb-10" v-html="text"></div>
            <a
              :href="url.url"
              class="block text-center button-height md:px-26 md:inline-block font-semibold | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
              @click.prevent="showFormOnScroll"
            >
              {{ linkText }}
            </a>
          </div>
          <div class="w-full lg:w-1/2 lg:pl-5">
            <img :src="resizedImage" :alt="image.alt" class="w-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  >>> .textSection {
    h1,
    h2,
    h3 {
      display: block;
      margin: 0 0 30px 0;
      font-size: 60px;
      font-weight: 600;
      line-height: 72px;
      text-transform: uppercase;
    }

    p {
      width: 530px;
      font-size: 18px;
      line-height: 22px;
    }
    @media (max-width: 1200px) {
      p {
        width: 100%;
      }
    }
    @media (max-width: 992px) {
      h1,
      h2,
      h3 {
        font-size: 45px;
        line-height: 50px;
      }
    }
  }
</style>
