<script>
  export default {
    name: 'NewBuilderImageWithLocations',
    props: {
      /** The heading for the block, if defined */
      title: {
        type: String,
        required: true,
        default: '',
      },
      sections: {
        type: Array,
        required: false,
        default: () => [],
      },
    },
  }
</script>

<template>
  <div v-if="sections.length > 0" class="galleryImagesWrapper">
    <h2 v-if="title" class="mb-6 md:mb-10">{{ title }}</h2>
    <div
      v-for="(section, index) in sections"
      :key="index"
      class="imagesGallery"
      :class="`${section.images.length <= 4 ? 'grid--cols--2' : ''}`"
    >
      <div v-for="(image, index) in section.images" :key="index" class="imageItem">
        <img
          :src="`${
            (index + 1) % 6 === 0
              ? image.widerImage
              : (index + 1) % 2 === 0 || (index + 1) % 3 === 0 || (index + 1) % 4 === 0
              ? image.wideImage
              : image.tallImageUrl
          }`"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .galleryImagesWrapper {
    padding-top: 160px;
  }

  .imagesGallery {
    display: grid;
    grid-template-rows: 300px 300px 340px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 40px;
    grid-column-gap: 40px;

    &.grid--cols--2 {
      grid-template-rows: 300px 300px;
    }

    .imageItem {
      grid-row-start: span 2;

      &:nth-child(6n + 2) {
        grid-row-start: span 1;
      }

      &:nth-child(6n + 3) {
        grid-row-start: span 1;
      }

      &:nth-child(6n + 4) {
        grid-row-start: span 1;
      }

      &:nth-child(6n + 6) {
        grid-column-start: span 2;
        height: 340px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @media (max-width: 1200px) {
    .imagesGallery {
      grid-template-rows: 240px 240px 300px;
      grid-row-gap: 20px;
      grid-column-gap: 20px;

      &.grid--cols--2 {
        grid-template-rows: 240px 240px;
      }

      .imageItem {
        &:nth-child(6n + 6) {
          grid-column-start: span 2;
          height: 300px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .galleryImagesWrapper {
      padding-top: 60px;
    }
  }
  @media (max-width: 800px) {
    .imagesGallery {
      display: flex;
      flex-direction: column;
      grid-row-gap: 0;
      grid-column-gap: 0;

      &:last-child {
        margin-bottom: 0;
      }

      &.grid--cols--2 {
        flex-direction: column;
        grid-row-gap: 0;
        grid-column-gap: 0;
      }

      .imageItem {
        height: 300px;
        margin-bottom: 20px;

        &:nth-child(6n + 6) {
          height: 300px;
        }
      }
    }
  }
</style>
