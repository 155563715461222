<script>
  import NewBuilderTopBannerWithTitle from '@/components/builder/newbuilder/NewBuilderTopBannerWithTitle'
  import NewBuilderTwoColumnsText from '@/components/builder/newbuilder/NewBuilderTwoColumnsText'
  import NewBuilderImageWithLocations from '@/components/builder/newbuilder/NewBuilderImageWithLocations'
  import NewBuilderOpenPositions from '@/components/builder/newbuilder/NewBuilderOpenPositions'
  import NewBuilderImageGallery from '@/components/builder/newbuilder/NewBuilderImageGallery'
  import NewBuilderTextLeftAndGoogleMapRight from '@/components/builder/TextLeftAndGoogleMapRight'
  import NewBuilderAppointmentForm from '@/components/builder/newbuilder/NewBuilderAppointmentForm'

  export default {
    name: 'NewBuilderBaseBlock',

    components: {
      NewBuilderTopBannerWithTitle,
      NewBuilderTwoColumnsText,
      NewBuilderImageWithLocations,
      NewBuilderOpenPositions,
      NewBuilderImageGallery,
      NewBuilderTextLeftAndGoogleMapRight,
      NewBuilderAppointmentForm,
    },

    props: {
      /** The type of child block to render */
      type: {
        type: String,
        required: true,
      },
      /** The content of the block (to feed to it's props) */
      content: {
        type: Object,
        required: true,
      },
    },

    computed: {
      /** Uses the returned blocks "type" handle property and appends it with 'Builder'  */
      resolveComponent: function () {
        return 'NewBuilder' + this.type
      },
      /** Checks to see if the component is imported and available in this component */
      isAvailable: function () {
        const componentName = this.resolveComponent
        return typeof this.$options.components[componentName] !== 'undefined'
      },
    },
  }
</script>

<template>
  <div class="relative">
    <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
      <component :is="resolveComponent" v-if="isAvailable" v-bind="content" />
    </div>
  </div>
</template>
