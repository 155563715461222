/** Formats numbers as currency strings */
export function formatCurrency(
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
  symbol = '$'
) {
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount
  const negativeSign = amount < 0 ? '-' : ''
  const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
  const j = i.length > 3 ? i.length % 3 : 0
  return (
    negativeSign +
    symbol +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : '')
  )
}

/** Capitalizes the first letter in every word of a string */
export function capitalizeString(string) {
  const splitStr = string.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

/** Converts a timestamp to a date format */
export function formatTimestamp(timestamp, separator = '.') {
  const newDate = new Date(timestamp * 1000)
  return newDate.getMonth() + 1 + separator + newDate.getDate() + separator + newDate.getFullYear()
}

/** Converts a category URI to a title */
export function categoryUriToTitle(categoryUri, capitalize) {
  capitalize = capitalize || false
  const cleanStr = categoryUri
    .replace('furniture/', '')
    .replace('lighting/', '')
    .replace('-', ' ')
    .split('/')
    .join(' / ')
  if (capitalize) {
    return capitalizeString(cleanStr)
  } else {
    return cleanStr
  }
}

export function trimHtml(html, options) {
  options = options || {}

  const limit = options.limit || 100
  const preserveTags = typeof options.preserveTags !== 'undefined' ? options.preserveTags : true
  const wordBreak = typeof options.wordBreak !== 'undefined' ? options.wordBreak : false
  const suffix = options.suffix || '...'
  const moreLink = options.moreLink || ''

  const arr = html
    .replace(/</g, '\n<')
    .replace(/>/g, '>\n')
    .replace(/\n\n/g, '\n')
    .replace(/^\n/g, '')
    .replace(/\n$/g, '')
    .split('\n')

  let sum = 0
  let row
  let cut
  let add
  let tagMatch
  let tagName
  const tagStack = []
  let more = false

  for (let i = 0; i < arr.length; i++) {
    row = arr[i]
    // count multiple spaces as one character
    const rowCut = row.replace(/[ ]+/g, ' ')

    if (!row.length) {
      continue
    }

    if (row[0] !== '<') {
      if (sum >= limit) {
        row = ''
      } else if (sum + rowCut.length >= limit) {
        cut = limit - sum

        if (row[cut - 1] === ' ') {
          while (cut) {
            cut -= 1
            if (row[cut - 1] !== ' ') {
              break
            }
          }
        } else {
          add = row.substring(cut).split('').indexOf(' ')

          // break on half of word
          if (!wordBreak) {
            if (add !== -1) {
              cut += add
            } else {
              cut = row.length
            }
          }
        }

        row = row.substring(0, cut) + suffix

        if (moreLink) {
          row += '<a href="' + moreLink + '" style="display:inline">»</a>'
        }

        sum = limit
        more = true
      } else {
        sum += rowCut.length
      }
    } else if (!preserveTags) {
      row = ''
    } else if (sum >= limit) {
      tagMatch = row.match(/[a-zA-Z]+/)
      tagName = tagMatch ? tagMatch[0] : ''

      if (tagName) {
        if (row.substring(0, 2) !== '</') {
          tagStack.push(tagName)
          row = ''
        } else {
          while (tagStack[tagStack.length - 1] !== tagName && tagStack.length) {
            tagStack.pop()
          }

          if (tagStack.length) {
            row = ''
          }

          tagStack.pop()
        }
      } else {
        row = ''
      }
    }

    arr[i] = row
  }

  return {
    html: arr.join('\n').replace(/\n/g, ' '),
    more: more,
  }
}

export function isHtml(str) {
  const doc = new DOMParser().parseFromString(str, 'text/html')
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1)
}

export function htmlToElement(html) {
  const template = document.createElement('template')
  // Never return a text node of whitespace as the result
  html = html.trim()
  template.innerHTML = html
  return template.content
}

export function insertIntoLastElement(html, insert) {
  const htmlElem = htmlToElement(html)
  const insertTemplate = document.createElement('template')
  insertTemplate.innerHTML = insert.trim()
  const insertElem = insertTemplate.content
  const serializer = new XMLSerializer()
  htmlElem.lastChild.appendChild(insertElem)
  return serializer.serializeToString(htmlElem)
}
