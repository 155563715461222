<script>
  import VLazyImage from 'v-lazy-image'

  /** A reusable designer tile preview. Makes use of VLazyImage to load preview images when they are in the viewport and
   * provides a loading animated SVG in base64 before the image loads to prevent layout jank.
   * See: https://github.com/alexjoverm/v-lazy-image */

  export default {
    name: 'DesignerTile',

    components: {
      VLazyImage,
    },

    props: {
      /** The designers URL */
      url: {
        type: String,
        required: true,
      },
      /** The designers name */
      title: {
        type: String,
        required: true,
      },
      /** The designers country of origin */
      country: {
        type: String,
        required: false,
        default: '',
      },
      /** The number of products from this designer */
      products: {
        type: [Number, String],
        required: false,
        default: null,
      },
      /** The designers headshot image as an object including params for alternate text, low rez and high rez image URLs */
      image: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** The designers sample product image as an object including params for alternate text, low rez and high rez image URLs */
      sampleImage: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
    },

    computed: {
      /** Creates the headshot image srcset string based on the high and low rez images provided */
      imageSrcSet: function () {
        let srcString = ''
        if (this.image.urls[0] && this.image.urls[1]) {
          srcString = this.image.urls[0] + ' 1x, ' + this.image.urls[1] + ' 2x '
        }
        return srcString
      },
      /** Creates the sample product image srcset string based on the high and low rez images provided */
      sampleSrcSet: function () {
        let srcString = ''
        if (this.sampleImage.urls[0] && this.sampleImage.urls[1]) {
          srcString = this.sampleImage.urls[0] + ' 1x, ' + this.sampleImage.urls[1] + ' 2x '
        }
        return srcString
      },
      /** Create the image sizes string */
      srcSizes: function () {
        return '50vw, (min-width: 768px) 33vw, (min-width: 1024px) 25vw, (min-width: 1560px) 20vw, (min-width: 1920px) 17vw'
      },
      /** Create the base64 SVG loading image */
      srcPlaceholder: function () {
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMTUgMzE1IiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0Ij4KICA8cmVjdCBjbGlwLXBhdGg9InVybCgjY2xpcFBhdGgtMTMpIiB4PSIwIiB5PSIwIiB3aWR0aD0iMzE1IiBoZWlnaHQ9IjMxNSIgc3R5bGU9ImZpbGw6IHVybCgmcXVvdDsjZ3JhZGllbnQtMTMmcXVvdDspOyI+PC9yZWN0PgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwUGF0aC0xMyI+CiAgICAgIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIzMTUiIGhlaWdodD0iMzE1Ij48L3JlY3Q+CiAgICA8L2NsaXBQYXRoPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkaWVudC0xMyI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmMGYwZjAiPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIgdmFsdWVzPSItMjsgMSIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2UwZTBlMCI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IiB2YWx1ZXM9Ii0xLjU7IDEuNSIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmMGYwZjAiPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIgdmFsdWVzPSItMTsgMiIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgPC9saW5lYXJHcmFkaWVudD4KICA8L2RlZnM+Cjwvc3ZnPgo='
      },
    },
  }
</script>

<template>
  <article>
    <a :href="url" class="block focus:outline-none || anchor" :aria-label="title">
      <figure
        v-if="image.urls.length"
        class="relative block border border-gray-200 mb-3 | md:mb-4 || image"
      >
        <VLazyImage
          :srcset="imageSrcSet"
          :src="image.urls[0]"
          :src-placeholder="srcPlaceholder"
          :sizes="srcSizes"
          :alt="image.alt"
        />
        <div
          v-if="sampleImage.urls.length"
          class="absolute inset-0 flex justify-center items-center bg-white opacity-0 transition-opacity duration-300 || sample"
        >
          <VLazyImage
            :srcset="sampleSrcSet"
            :src="sampleImage.urls[0]"
            :src-placeholder="srcPlaceholder"
            :sizes="srcSizes"
            :alt="sampleImage.alt"
          />
        </div>
      </figure>

      <header class="md:space-y-1">
        <h3 class="text-black leading-snug">
          <span class="inline align-middle mr-2">{{ title }}</span>
          <span
            class="inline-block align-middle bg-black opacity-0 transition-opacity duration-300 || indicator"
          ></span>
        </h3>

        <h4 v-if="country" class="text-xs text-black leading-snug">{{ country }}</h4>

        <h5 v-if="products" class="text-xs text-black leading-snug">
          {{ products }}
          <span v-if="products === 1 || products === '1'">Product</span>
          <span v-else>Products</span>
        </h5>
      </header>
    </a>
  </article>
</template>

<style scoped>
  .image img,
  .sample img {
    @apply min-w-full;
  }

  .indicator {
    width: 6px;
    height: 6px;
    border-radius: 3px;
  }

  .anchor {
    &:hover,
    &:focus {
      .sample {
        @apply opacity-100;
      }

      .indicator {
        @apply opacity-100;
      }
    }
  }
</style>
