<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseButton from '@/components/BaseButton'

  const { mapGetters, mapActions } = createNamespacedHelpers('designers')

  /** Pagination button that appears below the designer tile grid to allow users to load more designers.
   * Displays the total amount, total showing and amount left on next click. */

  export default {
    name: 'DesignerPagination',

    components: {
      BaseButton,
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getPaginationData', 'isLastPage']),
      /** Injects HTML for the current number of designers */
      shownQuantity: function () {
        return '<span data-cy="currentNumberOfBrands">' + this.getPaginationData.current + '</span>'
      },
      /** Injects HTML for the total number of designers */
      totalQuantity: function () {
        return '<span data-cy="totalNumberOfBrands">' + this.getPaginationData.total + '</span>'
      },
    },

    methods: {
      /** Vuex action methods */
      ...mapActions(['nextPage']),
    },
  }
</script>

<template>
  <nav
    aria-label="Product pagination"
    class="flex flex-col justify-center items-center pt-8 pb-4 border-t border-gray-300 | md:flex-row md:justify-between || wrapper"
  >
    <span
      class="text-gray-500 || desktop invisible"
      v-html="'Showing ' + shownQuantity + ' Designers'"
    />

    <transition name="fade">
      <BaseButton
        v-if="!isLastPage"
        :color="'gray'"
        :rounded="true"
        class="mb-2 | md:mb-0"
        @button-click="nextPage"
      >
        Load More
      </BaseButton>
    </transition>

    <span
      class="text-gray-500 || desktop invisible"
      aria-label="total products"
      v-html="'Total ' + totalQuantity + ' Designers'"
    />

    <span
      class="block text-xs text-gray-500 || mobile invisible"
      aria-current="true"
      aria-label="current products"
      v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Designers'"
    />
  </nav>
</template>

<style scoped lang="postcss">
  .wrapper {
    min-height: 123px;
  }

  .desktop {
    @apply hidden;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .wrapper {
      min-height: 97px;
    }

    .mobile {
      @apply hidden;
    }

    .desktop {
      @apply inline-block;
    }
  }
</style>
