var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",staticClass:"relative flex items-center w-full h-12 text-sm bg-white border cursor-pointer transition-colors duration-300 | focus:outline-none pr-0",class:{
    'justify-between': _vm.textAlign === 'left',
    'justify-center': _vm.textAlign === 'center',
    'justify-end': _vm.textAlign === 'end',
    'bg-gray-200': _vm.backgroundColor === 'gray',
    'border-gray-300': _vm.border,
    'border-transparent': !_vm.border,
    //'font-bold': emphasis,
    'pointer-events-none': _vm.disabled,
  },attrs:{"type":_vm.typeAttr,"disabled":_vm.disabledAttr,"aria-label":_vm.ariaLabel}},'component',_vm.$attrs,false),_vm.$listeners),[_vm._t("before"),_vm._v(" "),_c('span',{staticClass:"flex items-center min-w-0 text-gray-600"},[_vm._t("icon"),_vm._v(" "),_c('span',{staticClass:"relative min-w-0",class:{
        'text-left': _vm.textAlign === 'left',
        'text-center': _vm.textAlign === 'center',
      }},[_c('span',{staticClass:"truncate absolute left-0 top-0"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('span',{staticClass:"truncate invisible font-normal"},[_vm._v(_vm._s(_vm.label))])])],2),_vm._v(" "),_vm._t("after")],2)}
var staticRenderFns = []

export { render, staticRenderFns }