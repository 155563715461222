<script>
  import VLazyImage from 'v-lazy-image'
  // import BaseTagGroup from '@/components/BaseTagGroup.vue'

  /** A tile component used in the BuilderOffsetGallery component */

  export default {
    name: 'BuilderOffsetGalleryTile',

    components: {
      VLazyImage,
      // BaseTagGroup,
    },

    props: {
      /** The tile's title */
      title: {
        type: String,
        required: true,
      },
      /** The tile's sub heading */
      subheading: {
        type: String,
        required: false,
        default: null,
      },
      /** An array of tags for the tile */
      tags: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** The tile's URL link */
      url: {
        type: String,
        required: true,
      },
      /** The tile's link target */
      target: {
        type: String,
        required: false,
        default: '',
      },
      /** The shape of the tile */
      shape: {
        type: String,
        required: false,
        default: 'square',
        validator: (propValue) => {
          const propTypes = ['square', 'portrait', 'landscape']
          return propTypes.indexOf(propValue) > -1
        },
      },
      columns:{
        type:Boolean,
        default:false
      },
      /** The tile's image object */
      image: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** The tile's text alignment */
      align: {
        type: String,
        required: false,
        default: 'left',
        validator: (propValue) => {
          const propTypes = ['left', 'right']
          return propTypes.indexOf(propValue) > -1
        },
      },
    },

    computed: {
      /** Converts the array of tags string generated by Algolia for products so they can be used by the
       * BaseTagGroup component */
      tagsArray: function () {
        const tagArray = []
        this.tags.forEach((tag) => {
          tagArray.push({
            label: tag,
          })
        })
        return tagArray
      },
      /** Create the base64 SVG loading image */
      srcPlaceholder: function () {
        if (this.shape === 'portrait') {
          return 'data:image/svg+xml;base64,PHN2ZwogIHZpZXdCb3g9IjAgMCA1NjAgNzQ4IgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKPgogIDxyZWN0CiAgICB4PSIwIgogICAgeT0iMCIKICAgIHdpZHRoPSIxMDAlIgogICAgaGVpZ2h0PSIxMDAlIgogICAgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiCiAgICBzdHlsZT0nZmlsbDogdXJsKCIjZmlsbCIpOycKICAvPgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPgogICAgICA8cmVjdCB4PSIwIiB5PSIwIiByeD0iMCIgcnk9IjAiIHdpZHRoPSI1NjAiIGhlaWdodD0iNzQ4IiAvPgogICAgPC9jbGlwUGF0aD4KICAgIDxsaW5lYXJHcmFkaWVudCBpZD0iZmlsbCI+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIwLjU5OTk2NCIKICAgICAgICBzdG9wLWNvbG9yPSIjZjNmM2YzIgogICAgICAgIHN0b3Atb3BhY2l0eT0iMSIKICAgICAgPgogICAgICAgIDxhbmltYXRlCiAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJvZmZzZXQiCiAgICAgICAgICB2YWx1ZXM9Ii0yOyAtMjsgMSIKICAgICAgICAgIGtleVRpbWVzPSIwOyAwLjI1OyAxIgogICAgICAgICAgZHVyPSIycyIKICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICAgIC8+CiAgICAgIDwvc3RvcD4KICAgICAgPHN0b3AKICAgICAgICBvZmZzZXQ9IjEuNTk5OTYiCiAgICAgICAgc3RvcC1jb2xvcj0iI2VjZWJlYiIKICAgICAgICBzdG9wLW9wYWNpdHk9IjEiCiAgICAgID4KICAgICAgICA8YW5pbWF0ZQogICAgICAgICAgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IgogICAgICAgICAgdmFsdWVzPSItMTsgLTE7IDIiCiAgICAgICAgICBrZXlUaW1lcz0iMDsgMC4yNTsgMSIKICAgICAgICAgIGR1cj0iMnMiCiAgICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgICAvPgogICAgICA8L3N0b3A+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIyLjU5OTk2IgogICAgICAgIHN0b3AtY29sb3I9IiNmM2YzZjMiCiAgICAgICAgc3RvcC1vcGFjaXR5PSIxIgogICAgICA+CiAgICAgICAgPGFuaW1hdGUKICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIKICAgICAgICAgIHZhbHVlcz0iMDsgMDsgMyIKICAgICAgICAgIGtleVRpbWVzPSIwOyAwLjI1OyAxIgogICAgICAgICAgZHVyPSIycyIKICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICAgIC8+CiAgICAgIDwvc3RvcD4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgo8L3N2Zz4K'
        } else if (this.shape === 'landscape') {
          return 'data:image/svg+xml;base64,PHN2ZwogIHZpZXdCb3g9IjAgMCA1NjAgNDIwIgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKPgogIDxyZWN0CiAgICB4PSIwIgogICAgeT0iMCIKICAgIHdpZHRoPSIxMDAlIgogICAgaGVpZ2h0PSIxMDAlIgogICAgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiCiAgICBzdHlsZT0nZmlsbDogdXJsKCIjZmlsbCIpOycKICAvPgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPgogICAgICA8cmVjdCB4PSIwIiB5PSIwIiByeD0iMCIgcnk9IjAiIHdpZHRoPSI1NjAiIGhlaWdodD0iNDIwIiAvPgogICAgPC9jbGlwUGF0aD4KICAgIDxsaW5lYXJHcmFkaWVudCBpZD0iZmlsbCI+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIwLjU5OTk2NCIKICAgICAgICBzdG9wLWNvbG9yPSIjZjNmM2YzIgogICAgICAgIHN0b3Atb3BhY2l0eT0iMSIKICAgICAgPgogICAgICAgIDxhbmltYXRlCiAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJvZmZzZXQiCiAgICAgICAgICB2YWx1ZXM9Ii0yOyAtMjsgMSIKICAgICAgICAgIGtleVRpbWVzPSIwOyAwLjI1OyAxIgogICAgICAgICAgZHVyPSIycyIKICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICAgIC8+CiAgICAgIDwvc3RvcD4KICAgICAgPHN0b3AKICAgICAgICBvZmZzZXQ9IjEuNTk5OTYiCiAgICAgICAgc3RvcC1jb2xvcj0iI2VjZWJlYiIKICAgICAgICBzdG9wLW9wYWNpdHk9IjEiCiAgICAgID4KICAgICAgICA8YW5pbWF0ZQogICAgICAgICAgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IgogICAgICAgICAgdmFsdWVzPSItMTsgLTE7IDIiCiAgICAgICAgICBrZXlUaW1lcz0iMDsgMC4yNTsgMSIKICAgICAgICAgIGR1cj0iMnMiCiAgICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgICAvPgogICAgICA8L3N0b3A+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIyLjU5OTk2IgogICAgICAgIHN0b3AtY29sb3I9IiNmM2YzZjMiCiAgICAgICAgc3RvcC1vcGFjaXR5PSIxIgogICAgICA+CiAgICAgICAgPGFuaW1hdGUKICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIKICAgICAgICAgIHZhbHVlcz0iMDsgMDsgMyIKICAgICAgICAgIGtleVRpbWVzPSIwOyAwLjI1OyAxIgogICAgICAgICAgZHVyPSIycyIKICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICAgIC8+CiAgICAgIDwvc3RvcD4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgo8L3N2Zz4K'
        } else {
          return 'data:image/svg+xml;base64,PHN2ZwogIHZpZXdCb3g9IjAgMCA1NjAgNTYwIgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKPgogIDxyZWN0CiAgICB4PSIwIgogICAgeT0iMCIKICAgIHdpZHRoPSIxMDAlIgogICAgaGVpZ2h0PSIxMDAlIgogICAgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiCiAgICBzdHlsZT0nZmlsbDogdXJsKCIjZmlsbCIpOycKICAvPgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPgogICAgICA8cmVjdCB4PSIwIiB5PSIwIiByeD0iMCIgcnk9IjAiIHdpZHRoPSI1NjAiIGhlaWdodD0iNTYwIiAvPgogICAgPC9jbGlwUGF0aD4KICAgIDxsaW5lYXJHcmFkaWVudCBpZD0iZmlsbCI+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIwLjU5OTk2NCIKICAgICAgICBzdG9wLWNvbG9yPSIjZjNmM2YzIgogICAgICAgIHN0b3Atb3BhY2l0eT0iMSIKICAgICAgPgogICAgICAgIDxhbmltYXRlCiAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJvZmZzZXQiCiAgICAgICAgICB2YWx1ZXM9Ii0yOyAtMjsgMSIKICAgICAgICAgIGtleVRpbWVzPSIwOyAwLjI1OyAxIgogICAgICAgICAgZHVyPSIycyIKICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICAgIC8+CiAgICAgIDwvc3RvcD4KICAgICAgPHN0b3AKICAgICAgICBvZmZzZXQ9IjEuNTk5OTYiCiAgICAgICAgc3RvcC1jb2xvcj0iI2VjZWJlYiIKICAgICAgICBzdG9wLW9wYWNpdHk9IjEiCiAgICAgID4KICAgICAgICA8YW5pbWF0ZQogICAgICAgICAgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IgogICAgICAgICAgdmFsdWVzPSItMTsgLTE7IDIiCiAgICAgICAgICBrZXlUaW1lcz0iMDsgMC4yNTsgMSIKICAgICAgICAgIGR1cj0iMnMiCiAgICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgICAvPgogICAgICA8L3N0b3A+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIyLjU5OTk2IgogICAgICAgIHN0b3AtY29sb3I9IiNmM2YzZjMiCiAgICAgICAgc3RvcC1vcGFjaXR5PSIxIgogICAgICA+CiAgICAgICAgPGFuaW1hdGUKICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIKICAgICAgICAgIHZhbHVlcz0iMDsgMDsgMyIKICAgICAgICAgIGtleVRpbWVzPSIwOyAwLjI1OyAxIgogICAgICAgICAgZHVyPSIycyIKICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICAgIC8+CiAgICAgIDwvc3RvcD4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgo8L3N2Zz4K'
        }
      },
    },
  }
</script>

<template>
  <article 
    class="w-full"
    :class="`${ columns ? 'md:w-6/12 px-2 lg:px-4' : 'md:w-3/12' }`"
  >
    <a :href="url" :target="target" class="block focus:outline-none || anchor" :aria-label="title">
      <figure v-if="image.urls.length" class="relative block || image offsetImageFigure">
        <VLazyImage
          class="object-cover w-full h-full"
          :srcset="image.urls[0] + ' 1x,' + image.urls[1] + ' 2x'"
          :src="image.urls[0]"
          :src-placeholder="srcPlaceholder"
          :sizes="'(max-width: 767) 100vw, (min-width: 768) 50vw'"
          :alt="image.alt"
        />
        <div
          v-if="image.attribution"
          class="absolute bottom-0 right-0 py-1 text-xs inline px-4 rounded-tl z-10"
          :style="{ color: image.attributionColor }"
          v-html="image.attribution"
        ></div>
      </figure>

      <header class="mt-4 | md:mt-5">
        <h5 v-if="subheading" class="mb-1 text-xs leading-snug text-gray-600 tracking-wider" v-html="subheading" />

        <h4 v-if="title" class="leading-snug || indicator">
          <span class="inline align-middle | md:text-xl">{{ title }}</span>
        </h4>
        <!-- removed for the moment as there is a bug associated with the builder module where the tags are all false -->
        <!-- <div v-if="tagsArray.length" class="mt-2 | md:mt-3">
          <BaseTagGroup :tags="tagsArray" />
        </div> -->
      </header>
    </a>
  </article>
</template>

<style scoped lang="postcss">
  .image img {
    @apply min-w-full;
  }

  .image::after {
    content: '';
    @apply absolute inset-0 block bg-gray-900 opacity-0 transition-opacity duration-300;
  }

  .more--height {
    .offsetImageFigure {
      height: 450px;
      @media(max-width:992px) {
        height: 320px;
      }
    }
  }

  .offsetImageFigure {
    height: 320px;
    @media(max-width:992px) {
      height: 240px;
    }
  }

  .indicator::before,
  .indicator::after {
    width: 6px;
    height: 6px;
    content: '';
    border-radius: 3px;
    @apply align-middle bg-black opacity-0 transition-opacity duration-300;
  }

  .indicator::before {
    @apply hidden mr-2;
  }

  .indicator::after {
    @apply inline-block ml-2;
  }

  .anchor {
    &:hover,
    &:focus {
      .image::after {
        @apply opacity-25;
      }

      .indicator::before,
      .indicator::after {
        @apply opacity-100;
      }
    }
  }

  @screen md {
    .tile-right {
      @apply text-right;

      .indicator::before {
        @apply inline-block;
      }

      .indicator::after {
        @apply hidden;
      }
    }
  }
</style>
