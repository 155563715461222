<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import BuilderHeroSliderSlide from '@/components/builder/BuilderHeroSliderSlide'

  /** Content builder hero slider block. Uses VueSlickCarousel for interactions
   * and the BuilderHeroSliderSlide component for slides.
   * See: https://github.com/gs-shop/vue-slick-carousel */

  export default {
    name: 'BuilderHeroSlider',

    components: {
      VueSlickCarousel,
      BuilderHeroSliderSlide,
    },

    props: {
      /** An array of objects of slides to display */
      slides: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** Whether or not to autoplay the slider on load */
      autoplay: {
        type: Boolean,
        required: false,
      },
      /** The label of the block section (used by TOC block) */
      label: {
        type: String,
        required: false,
        default: '',
      },
      /** An ID anchor of the block section (used by TOC block) */
      anchor: {
        type: String,
        required: false,
        default: '',
      },
    },

    data() {
      return {
        reset: 0,
        noticeHeight: '0px',
        headerHeight: '0px',
        /** If you change the padding of the slide, you have to change these to match */
        slidePadding: 'p-4 md:p-16 lg:p-25',
        settings: {
          slidesToShow: 1,
          slidesToScroll:1,
          arrows: false,
          dots: true,
          touchThreshold:10,
          dotsClass:'dots--container',
          draggable: true,
          swipe: true,
          autoplay: this.autoplay, /* this.autoplay */
          autoplaySpeed: 6000,
          pauseOnFocus: false,
          adaptiveHeight:true
        },
      }
    },

    computed: {
      slideHeight: function () {
        /** Offsets the height of the slide to account for a notice bar at the bottom of the screen */
        // @TODO: Make sure this works with a notice still - KF Mar 4 2022
        return `height: calc(100vh - ${this.headerHeight})`
      },
    },

    mounted() {
      const notice = document.getElementById('notice')
      this.noticeHeight = notice ? notice.offsetHeight + 'px' : '0px'

      const header = document.getElementById('ref-header')
      this.headerHeight = header ? header.offsetHeight + 'px' : '0px'

      // Start on a random slide when mounted
      this.startSlideshow()
    },

    methods: {
      showNext() {
        this.$refs.slider.next()
        this.$refs.next.blur()
      },
      showPrev() {
        this.$refs.slider.prev()
        this.$refs.prev.blur()
      },
      /** Starts the slideshow at a random slide */
      startSlideshow() {
        // const min = Math.ceil(0)
        // const max = Math.floor(this.slides.length)
        // const startAt = Math.floor(Math.random() * (max - min + 1) + min)
        // this.$refs.slider.goTo(startAt, true)
      },
    },
  }
</script>

<template>
  <section v-show="slides.length" :id="anchor" class="relative overflow-hidden bg--grey">
    <VueSlickCarousel
      v-if="slides.length"
      ref="slider"
      :key="reset"
      v-bind="settings"
      class="slickListWrapper"
    >
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="relative"
      >
        <BuilderHeroSliderSlide :blocks="slide.blocks"  :parentRef="$refs" v-bind="slide" :slide-height="slideHeight" :all-slides="slides.length" :index="index" />
      </div>
    </VueSlickCarousel>
  </section>
</template>

<style scoped lang="postcss">
  .bg--grey {
    background: #FCF6EE;
  }
  >>> .slickListWrapper {
    .slick-list {
      overflow: hidden !important;
    }
    .dots--container {
      position: absolute;
      bottom: 40px;
      right: calc((58.33% - 152px) / 2);
      &.dots--last {
        right: 24px;
        li {
          background: #000;
        }
      }
      li {
        border-radius: 100%;
        width: 14px;
        height: 14px;
        background: #fff;
        opacity: 0.5;
        float: left;
        margin:0 18px 0 0;
        cursor: pointer;
        border: 1px solid #000;
        button {
          display: none;
        }
        &:last-child {
          margin-right: 0;
        }
        &.slick-active {
          opacity: 1;
        }
      }
      @media(max-height:701px) {
        &.dots--last {
          bottom: 16px;
        }
      }
      @media(max-width:1024px) {
        display: none !important;
      }
    }
  }
</style>
