import { firstBy } from 'thenby'
import algoliasearch from 'algoliasearch/lite'
import { compStr, groupBy } from '@/utilities/comparators'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    viewTypeOptions: [
      {
        label: 'Thumbnails',
        value: 'thumbnails',
      },
      {
        label: 'List',
        value: 'list',
      },
    ],
    viewType: 'thumbnails',
    sortOptions: [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Country',
        value: 'country',
      },
    ],
    sort: 'name',
    items: [],
    pagination: {
      currentPage: 1,
      pageSize: 72,
    },
    isTradeAccount: false,
    tradeBrands: [],
  },

  mutations: {
    tradeOnlyBrands(state, value) {
      state.tradeBrands = value
    },
    setTradeAccount(state, value) {
      state.isTradeAccount = value
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setViewType(state, value) {
      state.viewType = value
    },
    setSort(state, value) {
      state.sort = value
    },
    setItems(state, value) {
      state.items = value
    },
    incrementPage(state) {
      // eslint-disable-next-line no-console
      console.log('Incrementing page (Vuex incrementPage mutator)')
      state.pagination.currentPage++
    },
    decrementPage(state) {
      state.pagination.currentPage--
    },
  },

  getters: {
    getIsLoading(state) {
      return state.isLoading
    },
    getViewTypeOptions(state) {
      return state.viewTypeOptions
    },
    getViewType(state) {
      return state.viewType
    },
    getSortOptions(state) {
      return state.sortOptions
    },
    getSort(state) {
      return state.sort
    },
    getTotalItems(state) {
      return state.items.length
    },
    getTradeOrNotBrands(state) {
      if (!state.isTradeAccount) {
        return state.items.filter((item) => !state.tradeBrands.includes(item.id))
      } else {
        return state.items
      }
    },
    getSortedItems(state, getters) {
      const allBrands = getters.getTradeOrNotBrands
      if (state.sort === 'country') {
        return allBrands.sort(
          firstBy('country', { ignoreCase: true, direction: 'asc' }).thenBy('title', {
            ignoreCase: true,
            direction: 'asc',
          })
        )
      }
      if (state.sort === 'name') {
        return allBrands.sort((a, b) => {
          return compStr(a.title, b.title, 'asc')
        })
      }
    },

    getPreviewItem: (state) => (title) => {
      return state.items.find((item) => item.title === title)
    },

    getStructuredItems(state, getters) {
      /**  Structure by country */
      if (state.sort === 'country') {
        const countryArr = []
        const itemsByCountry = []
        const groupedByCountry = groupBy(getters.getSortedItems, (item) => item.country)

        getters.getSortedItems.forEach(function (item) {
          if (!countryArr.includes(item.country) && item.country !== '') {
            countryArr.push(item.country)
          }
        })

        countryArr.sort()

        countryArr.forEach(function (country) {
          itemsByCountry.push({
            heading: country,
            items: groupedByCountry.get(country).sort((a, b) => {
              return compStr(a.title, b.title, 'asc')
            }),
          })
        })

        return itemsByCountry
      }

      /** Structure by first letter in name */
      if (state.sort === 'name') {
        const letterArr = []
        const itemsByLetter = []

        getters.getSortedItems.forEach((item) => {
          const firstLetter = item.title.charAt(0).toUpperCase()
          if (!letterArr.includes(firstLetter) && firstLetter !== '') {
            letterArr.push(firstLetter)
          }
        })

        letterArr.sort()

        letterArr.forEach((letter) => {
          const matchingBrands = []
          getters.getSortedItems.forEach((brand) => {
            if (brand.title.charAt(0).toUpperCase() === letter) {
              matchingBrands.push(brand)
            }
          })
          itemsByLetter.push({
            heading: letter,
            items: matchingBrands.sort((a, b) => {
              return compStr(a.title, b.title, 'asc')
            }),
          })
        })

        return itemsByLetter
      }
    },

    getPaginatedItems(state, getters) {
      const size = state.pagination.pageSize
      const current = state.pagination.currentPage
      const last = current * size

      return getters.getSortedItems.slice(0, last)
    },

    getPaginationData(state, getters) {
      const current = getters.getPaginatedItems.length
      const total = getters.getSortedItems.length
      const remaining = total - current
      const toLoad = remaining < state.pagination.pageSize ? remaining : state.pagination.pageSize

      return {
        current: current,
        total: total,
        remaining: remaining,
        toLoad: toLoad,
      }
    },

    isLastPage(state, getters) {
      const total = getters.getSortedItems.length
      const pageSize = state.pagination.pageSize
      const current = state.pagination.currentPage

      return pageSize * current >= total
    },
  },

  actions: {
    setTradeAccount({ commit }, data) {
      commit('setTradeAccount', data)
    },
    setTradeOnlyBrands({ commit }, data) {
      commit('tradeOnlyBrands', data)
    },
    initItems(context) {
      /* Get the keys and list to operate on from the packages/fwork/.env file */
      const appId = process.env.VUE_APP_ALGOLIA_APPLICATION_ID
      const apiKey = process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY
      const indexName = process.env.VUE_APP_ALGOLIA_BRANDS_INDEX

      // Set the isLoading state
      context.commit('setIsLoading', true)

      // Initialize the index search
      const client = algoliasearch(appId, apiKey)
      const index = client.initIndex(indexName)

      // Make the call to Algolia and set the results
      index
        .search('', {
          hitsPerPage: 1000,
        })
        .then(({ hits }) => {
          context.commit('setItems', hits)
          context.commit('setIsLoading', false)
        })
    },

    searchBrands(context, { query }) {
      /* Get the keys and list to operate on from the packages/fwork/.env file */
      const appId = process.env.VUE_APP_ALGOLIA_APPLICATION_ID
      const apiKey = process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY
      const indexName = process.env.VUE_APP_ALGOLIA_BRANDS_INDEX

      // Set the isLoading state
      context.commit('setIsLoading', true)

      // Initialize the index search
      const client = algoliasearch(appId, apiKey)
      const index = client.initIndex(indexName)

      // Run the search and then set the results in the products state
      index
        .search(query, {
          hitsPerPage: 1000,
          getRankingInfo: true,
        })
        .then(({ hits }) => {
          context.commit('setItems', hits)
          context.commit('setIsLoading', false)
        })
    },

    setSort({ commit }, sort) {
      commit('setSort', sort)
    },

    setViewType({ commit }, type) {
      commit('setViewType', type)
    },

    nextPage({ commit, getters }) {
      if (!getters.isLastPage) {
        // eslint-disable-next-line no-console
        console.log('Not the last page (Vuex nextPage action)')
        commit('incrementPage')
      }
    },
  },
}
