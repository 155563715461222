<script>
  import IconSpinner from '@/images/icons/spinner.svg'

  /** Reusable base button component for buttons and anchor tags that need to look like buttons
   * Emits a 'buttonClick' event when clicked for parent components to handle */
  export default {
    name: 'BaseButton',

    components: {
      IconSpinner,
    },

    props: {
      /** 'white', 'gray' or 'black' colors */
      color: {
        type: String,
        required: false,
        default: 'white',
        validator: (propValue) => {
          const validTypes = ['white', 'gray', 'black']
          return validTypes.indexOf(propValue) > -1
        },
      },
      /** Rounded (pill) shaped button */
      rounded: {
        type: Boolean,
        required: false,
      },
      /** Text should be capitalized and not uppercase */
      capitalize: {
        type: Boolean,
        required: false,
      },
      /** Full width */
      fullWidth: {
        type: Boolean,
        required: false,
      },
      /** If a URL is defined the button will be a link element. If not the button will be a button element
       * and a click event will be submitted on click */
      url: {
        type: String,
        required: false,
        default: null,
      },
      type: {
        type: String,
        default: 'button',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      additionalBtnClasses: {
        type: String,
        default: '',
      },
    },

    computed: {
      /** Creates the buttons CSS class based on it's props */
      buttonClass() {
        let cssClass = 'btn'

        if (this.color !== 'white' && !this.disabled) {
          cssClass += ' btn--' + this.color
        }

        if (this.disabled) {
          cssClass += ' disabled'
        }

        if (this.disabled) {
          cssClass += ' btn--gray'
        }

        if (this.rounded) {
          cssClass += ' btn--rounded'
        }

        if (this.capitalize) {
          cssClass += ' capitalize'
        } else {
          cssClass += ' uppercase'
        }
        if (this.fullWidth) {
          cssClass += ' btn--full'
        }

        return cssClass
      },
    },

    methods: {
      /** Emits a click event to the parent component */
      handleClick(event) {
        this.$emit('button-click')
        event.target.blur()
      },
    },
  }
</script>

<template>
  <div :class="{ block: fullWidth, 'inline-block': !fullWidth }">
    <a v-if="url" :href="url" class="focus:outline-none" :class="`${buttonClass} `">
      <span class="inline-block border-transparent border-b transition-colors duration-300"
        ><slot
      /></span>
    </a>
    <button
      v-else
      ref="button"
      :type="type"
      :disabled="disabled || loading"
      class="relative focus:outline-none"
      :class="`${buttonClass} ${additionalBtnClasses}`"
      @click="handleClick"
    >
      <span class="inline-block border-transparent border-b transition-colors duration-300"
        ><slot
      /></span>
      <IconSpinner v-if="loading" class="absolute right-0 top-0 bottom-0 my-auto" />
    </button>
  </div>
</template>

<style scoped lang="postcss">
  button {
    padding: 20px 80px;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.1em;
    font-weight: 600;
  }
  a,
  button {
    &:hover,
    &:focus {
      &:not(:disabled) {
        &.btn--white span,
        &.btn--gray span {
          @apply border-black;
        }

        &.btn--black span {
          @apply border-white;
        }
      }
    }

    &:disabled {
      &.btn--white span,
      &.btn--gray span,
      &.btn--black span {
        @apply border-transparent;
      }
    }
  }
</style>
