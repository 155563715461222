<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseViewToggle from '@/components/BaseViewToggle'
  import BaseFilterSelect from '@/components/BaseFilterSelect.vue'
  import ProfilePageHeader from '@/components/profiles/ProfilePageHeader'
  import DesignerTileGrid from '@/components/designers/DesignerTileGrid'
  import DesignerList from '@/components/designers/DesignerList'

  const { mapGetters, mapActions } = createNamespacedHelpers('designers')

  /** This is the main view template for the Designer Listing Page. It mostly pulls in other components. */

  export default {
    name: 'DesignerListingPage',

    components: {
      BaseViewToggle,
      BaseFilterSelect,
      ProfilePageHeader,
      DesignerTileGrid,
      DesignerList,
    },

    props: {
      title: {
        type: String,
        required: false,
        default: 'Designers',
      },
      summary: {
        type: String,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        lettersView:'',
        alphaClass:'alphaSectionByName'
      }
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getSortOptions', 'getSort', 'getViewTypeOptions', 'getViewType', 'getStructuredItems']),
    },

    created() {
      /** Dispatch actions in the Vuex store to get all designers */
      this.$store.dispatch('designers/initItems')

      /** Clear browsing snapshot if it exists */
      this.$store.dispatch('products/clearBrowsingSnapshot')
    },

    methods: {
      /** Vuex action methods */
      ...mapActions(['setViewType', 'setSort']),
      changeLettersView(value) {
        this.lettersView = value
        if(this.lettersView === 'name') {
          this.alphaClass = 'alphaSectionByName'
        }else {
          this.alphaClass = 'alphaSectionByCountry'
        }
        this.setSort(value)
      },
      scrollToElementOnClick(target) {
        const element = document.querySelector(`#item-${target}`)
        if (element) {
          const noticeElement = document.querySelector('#notice')
          let noticeHeight = 0
          if(noticeElement !== null) {
            noticeHeight = document.querySelector('#notice').offsetHeight
          }else {
            noticeHeight = 0
          }
          const headerHeight = document.querySelector('#ref-header').offsetHeight
          const yOffset = headerHeight + noticeHeight + 20

          const y = (element.getBoundingClientRect().top + window.pageYOffset) - yOffset
          window.scrollTo({top: y - 62, behavior: 'smooth'})
        }
      },
    },
  }
</script>

<template>
  <main id="main-content">
    <article class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
      <ProfilePageHeader :title="title" :summary="summary" />

      <div
        class="flex justify-between items-center py-4 border-t border-b border-gray-300 | lg:pt-10 lg:pb-8 lg:border-b-0"
      >
        <BaseViewToggle
          :options="getViewTypeOptions"
          :value="getViewType"
          @change="setViewType($event)"
        />
        <div class="flex items-center justify-between">
          <BaseFilterSelect
            label="Sort by"
            :options="getSortOptions"
            :value="getSort"
            class="flex-shrink || sort-select"
            @change="changeLettersView($event)"
          />
        </div>
      </div>
      <DesignerTileGrid v-if="getViewType === 'thumbnails'" />

      <div v-if="getViewType === 'list'" class="py-4 | lg:pb-12">

        <div 
          ref="alphaSection"
          class="w-full alphaSection mb-14 relative z-40 overflow-x-auto md:overflow-x-hidden"
        >
          <ul 
            class="flex flex-wrap flex-row w-full justify-start md:justify-between border-t border-b border-gray-300 py-5 headingList"
            :class="alphaClass"
          >
            <li 
              v-for="(item, x) in getStructuredItems"
              :key="x"
              class="mx-4 md:mx-0"
            >
              <span
                class="font-semibold uppercase text-black block m-0 text-sm cursor-pointer hover:text-red-700"
                @click="scrollToElementOnClick(item.heading)"
              >{{ item.heading }}</span>
            </li>
          </ul>
        </div>

        <DesignerList :setLettersSize="lettersView" />
      </div>
    </article>
  </main>
</template>

<style scoped lang="postcss">
.alphaSection {
  ul.alphaSectionByCountry {
    display: none;
  }
  &.fixed {
    position: fixed;
    z-index: 1050;
    background: #fff;
    left: 0;
    ul {
      padding-left: 24px;
      padding-right: 24px;
    }
    &.bigFixed {
      top:128px;
    }
    &.smallFixed {
      top:72px;
    }
  }
  @media(max-width:1024px) {
    &.fixed {
      &.bigFixed {
        top:102px;
      }
      &.smallFixed {
        top:56px;
      }
    }
    &::-webkit-scrollbar {
      height: 5px;
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #6F6F6F;
      outline:none;
      border-radius: 100px;
    }
  }
  @media(max-width:992px) {
    &.fixed {
      &.bigFixed {
        top:80px;
      }
    }
  }
  @media(max-width:767px) {
    &.fixed {
      ul {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.headingList {
  li {
    span {
      font-family: 'Inter';
    }
  }
  @media(max-width:767px) {
    width: 1000px;
    &.alphaSectionByCountry {
      width: 1400px;
    }
  }
  &.alphaSectionByCountry {
    li {
      span {
        text-transform: initial;
        font-size: 13px;
      }
    }
    @media(max-width:1350px) {
      justify-content: flex-start;
      li {
        margin: 0 5px;
      }
    }
  }
}
  /* purgecss start ignore */
  >>> .sort-select {
    max-width: 150px;
  }
  /* purgecss end ignore */
</style>
