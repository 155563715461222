<script>
  /** A Reusable TextRow component that can be used in content builder and standalone templates */

  export default {
    name: 'BuilderGeneralTextRow',
    props: {
      /** The heading for the block, if defined */
      heading: {
        type: String,
        required: false,
        default: '',
      },
      /** The text that the component will display */
      text: {
        type: String,
        required: false,
        default: '',
      },
      /** Prop to set the component text alignment */
      align: {
        type: String,
        default: 'center',
        validator: (value) => {
          // The value must match one of these strings
          return ['center', 'left', 'right', 'justify'].indexOf(value) !== -1
        },
      },
      /** Component font size */
      size: {
        type: String,
        default: 'base',
        validator: (value) => {
          // The value must match one of these strings
          return ['base', 'md', 'lg', 'xl'].indexOf(value) !== -1
        },
      },
      /** text font weight */
      weight: {
        type: String,
        default: 'normal',
        validator: (value) => {
          // The value must match one of these strings
          return ['normal', 'medium', 'bold'].indexOf(value) !== -1
        },
      },
      /** text font weight */
      color: {
        type: String,
        default: 'text-black',
      },
      /** CTA button */
      button: {
        type: Object,
        default: () => ({}),
      },
      /** Component bottom border */
      hasBottomBorder: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      /** font size fo the text */
      textSize() {
        return `text-base lg:text-${this.size} ${
        this.size === 'lg' || this.size === 'xl' ? 'leading-6' : 'leading-6'
        }`
      },
      /** font weight fo the text */
      fontWeight() {
        return `font-${this.weight}`
      },
      /** text alignment */
      alignment() {
        return `text-${this.align}`
      },
      /** text additional class */
      additionalClass() {
        return `${this.textSize} ${this.fontWeight} ${this.alignment} ${this.color}`
      },
    },
  }
</script>

<template>
  <div>
    <div class="w-full flex flex-col lg:flex-row flex-wrap py-12 lg:py-20">
      <div class="w-full lg:w-1/3">
        <h1 v-if="heading" class="mb-4 lg:mb-0">{{ heading }} </h1>
      </div>
      <div
        v-if="text"
        class="w-full flex flex-col w-full space-y-4 lg:w-2/3 | wysiwyg pl-0 lg:pl-4"
      >
        <div :class="additionalClass" v-html="text" />
        <a
          v-if="button.url"
          :href="button.url"
          :target="button.target"
          class="py-3 w-full md:max-w-80 text-center | mt-4 | bg-black text-white | rounded-md | hover:bg-gray-900 focus:bg-gray-900 | font-bold"
        >
          {{ button.text }}
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  br {
    display: block !important;
    margin-bottom: 1.5em !important;
  }

  .general-text-row {
    >>> {
      a {
        @apply w-full text-center;
      }
    }
  }

  @screen md {
    .general-text-row {
      >>> {
        a {
          width: fit-content;
          @apply text-center;
        }
      }
    }
  }
</style>
