<script>
  import { createNamespacedHelpers } from 'vuex'
  import ImageLogo from '@/images/logo.svg'
  import MenuArrow from '@/images/icons/menu-arrow.svg'
  import IconMenu from '@/images/icons/menu.svg'
  import IconX from '@/images/icons/x.svg'
  import IconSearch from '@/images/icons/search.svg'
  import IconClose from '@/images/icons/close.svg'
  import IconAccount from '@/images/icons/account.svg'
  // import IconProjects from '@/images/icons/projects.svg'
  import IconCart from '@/images/icons/cart-v2.svg'
  import IconPlus from '@/images/icons/plus.svg'
  import IconMinus from '@/images/icons/minus.svg'
  // import IconFolder from '@/images/icons/folder.svg'
  import LegacySearch from '@/components/legacy/LegacySearch'

  const { mapState, mapGetters } = createNamespacedHelpers('global')

  export default {
    components: {
      ImageLogo,
      IconMenu,
      MenuArrow,
      IconX,
      IconCart,
      IconSearch,
      IconClose,
      IconAccount,
      // IconProjects,
      IconPlus,
      IconMinus,
      LegacySearch,
      // IconFolder,
    },
    props: {
      /** Whether the header should be thicker than it usually is when the viewport is at the top of the page (not scrolled down). */
      thickAtTop: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        scrolledDown: false,
        mobileMenuIsOpen: false,
        mobileActiveNavItemIds: [],
        desktopActiveNavItemId: null,
        desktopActiveNavItemMethod: null,
        desktopActiveNavItemTimeout: null,
        searchOpen: false,
        term: '',
        leftOffset: 0,
      }
    },
    computed: {
      ...mapState(['siteUrl', 'primaryNavigation', 'pages', 'globals']),
      ...mapGetters(['loggedIn']),
      thick() {
        const thickAtTop = window.location.pathname === '/'
        return thickAtTop && !this.scrolledDown
      },
      searchInputRef() {
        return this.$refs.searchInput
      },
      desktopSearchButtonRef() {
        return this.$refs.desktopSearchButtonRef
      },
    },
    created() {
      const onScroll = () => {
        this.scrolledDown = window.scrollY > 0
      }
      window.addEventListener('scroll', onScroll)
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('scroll', onScroll)
      })
    },
    methods: {
      showPopup(event) {
        if (event.target.classList.contains('openAppointmentPopup')) {
          event.preventDefault()
          const popup = document.querySelector('.appointmentFormPopup')
          const overlay = document.querySelector('.mainOverlay')
          const body = document.querySelector('body')
          overlay.style.display = 'block'
          popup.style.display = 'block'
          body.classList.add('overflow-y-hidden')
        }
      },
      openSearch() {
        this.searchOpen = true
        this.searchInputRef.focus()
      },
      closeSearch() {
        this.searchOpen = false
      },
      /** Toggles the search modal. Copied from LegacyHeader. */
      toggleSearch() {
        // Hide/show the notification element if it exists
        const noticeElem = document.getElementById('notice')
        if (noticeElem) {
          if (!this.searchOpen) {
            noticeElem.classList.add('is-hidden')
            this.term = ''
          } else {
            noticeElem.classList.remove('is-hidden')
          }
        }
        // Toggle the search
        this.searchOpen = !this.searchOpen

        if (this.searchOpen) {
          this.searchInputRef.focus()
        } else {
          this.searchInputRef.blur()
          this.desktopSearchButtonRef.blur()
        }
      },
      /** Returns whether the given nav item's subnav is open on mobile. */
      mobileSubnavIsOpen(navItem) {
        return this.mobileActiveNavItemIds.some((activeNavItemId) => activeNavItemId === navItem.id)
      },
      /** Opens a nav item's subnav on mobile. */
      mobileOpenSubnav(navItem) {
        this.mobileActiveNavItemIds = [
          ...this.mobileActiveNavItemIds.filter(
            (activeNavItemId) => activeNavItemId !== navItem.id
          ),
          navItem.id,
        ]
      },
      /** Closes a nav item's subnav on mobile. */
      mobileCloseSubnav(navItem) {
        this.mobileActiveNavItemIds = this.mobileActiveNavItemIds.filter(
          (activeNavItemId) => activeNavItemId !== navItem.id
        )
      },
      /** Opens or closes a nav item's subnav on mobile. */
      mobileToggleSubnav(navItem) {
        if (this.mobileSubnavIsOpen(navItem)) {
          this.mobileCloseSubnav(navItem)
        } else {
          this.mobileOpenSubnav(navItem)
        }
      },
      /** Sets the active nav item on desktop, with a `method` of either 'hover' or 'focus'. */
      desktopSetActiveNavItem(navItem, method, force = false, index) {
        // If this item was already made active by focus and we're now hovering it, don't change the method (unless `force` is `true`)
        const current = this.$refs.navParentItem[index]
        const leftOffset = current.getBoundingClientRect().left.toFixed(0) - 12
        if (index === 0) {
          this.leftOffset = 0
        } else {
          this.leftOffset = leftOffset
        }
        if (
          navItem.id === this.desktopActiveNavItemId &&
          this.desktopActiveNavItemMethod === 'focus' &&
          method === 'hover' &&
          !force
        ) {
          return
        }
        clearTimeout(this.desktopActiveNavItemTimeout)
        this.desktopActiveNavItemId = navItem.id
        this.desktopActiveNavItemMethod = method
      },
      /** Unsets the active nav item if it's the passed one and it was made active with the passed `method`. */
      desktopUnsetActiveNavItem(navItem, method) {
        if (
          navItem.id === this.desktopActiveNavItemId &&
          (!method || method === this.desktopActiveNavItemMethod)
        ) {
          this.desktopActiveNavItemTimeout = setTimeout(
            () => {
              this.desktopActiveNavItemId = null
              this.desktopActiveNavItemMethod = null
            },
            method === 'hover' ? 500 : 0
          )
        }
      },
    },
  }
</script>

<template>
  <header
    id="ref-header"
    :class="`w-full z-50 inset-x-0 fixed top-0 max-w-full ${
      thick ? 'h-header-mobile-thick' : 'h-header-mobile'
    } leading-tighter transition-box duration-300 | ${
      thick ? 'lg:h-header-desktop-thick' : 'lg:h-header-desktop'
    }`"
  >
    <div
      class="header--search px-4 lg:px-6 h-full w-full border-b border-gray-300 bg-white"
      :class="`${searchOpen ? 'active' : ''}`"
    >
      <form action="/search/" role="search" class="h-full w-full">
        <input
          id="search-modal"
          ref="searchInput"
          v-model="term"
          type="text"
          class="w-full h-full block pl-14 pr-6"
          name="searchTerm"
          :tabindex="searchOpen ? 0 : -1"
          placeholder="Search"
        />
        <button type="submit" class="h-5 w-5 ml-6">
          <IconSearch class="w-full h-full" />
        </button>
      </form>
      <span class="header--search--close w-5 h-5 mr-6" @click="closeSearch">
        <IconClose class="w-full h-full" />
      </span>
    </div>

    <div class="-z-10 absolute inset-0 border-b border-gray-300 bg-white"></div>

    <!-- Skip links (http://web-accessibility.carnegiemuseums.org/code/skip-link) -->
    <div class="absolute left-0 ml-13 top-0 mt-3 | lg:ml-6">
      <a
        href="javascript:void(0)"
        class="sr-only focus:not-sr-only focus:border-2 border-gray-500"
        @click.prevent="toggleSearch"
      >
        Skip to<br class="lg:hidden" />
        search
      </a>
      <a href="#main-content" class="sr-only focus:not-sr-only">
        Skip to<br class="lg:hidden" />
        main content
      </a>
    </div>

    <div class="flex mx-auto lg:max-w-screen-2xl px-4 md:px-6 h-full">
      <!-- Main logo -->
      <div
        class="header--logo"
        :class="`xl:mr-12 mr-6 ${
          thick ? 'w-header-mobile-thick' : 'w-header-mobile'
        } flex-shrink-0 transition-box duration-300 | ${
          thick ? 'xl:w-header-desktop-thick w-header-desktop-thin' : 'lg:w-header-desktop'
        }`"
      >
        <a
          :href="siteUrl"
          class="flex items-end w-full h-full bg-black hover:bg-gray-900 transition-colors duration-300 text-white"
          style="padding: 15%"
        >
          <ImageLogo class="w-full fill-current" />
        </a>
      </div>

      <!-- Mobile menu button -->
      <div class="order-first -ml-2 my-auto flex-1 | lg:hidden">
        <button
          type="button"
          :aria-label="mobileMenuIsOpen ? 'Close menu' : 'Open menu'"
          class="flex justify-center items-center w-10 h-10 rounded-full hover:bg-gray-200 transition-colors duration-300"
          @click="mobileMenuIsOpen = !mobileMenuIsOpen"
        >
          <IconX v-if="mobileMenuIsOpen" class="w-4 h-4" />
          <IconMenu v-else class="w-6 h-6" />
        </button>
      </div>

      <!-- Mobile cart icon -->
      <div class="-mr-2 my-auto flex justify-end flex-1 | lg:hidden">
        <a
          :href="pages.cart.url"
          :aria-label="pages.cart.title"
          class="flex justify-center items-center w-10 h-10 rounded-full hover:bg-gray-200 transition-colors duration-300"
        >
          <IconCart />
        </a>
      </div>

      <!-- Mobile menu -->
      <div
        v-if="mobileMenuIsOpen"
        :class="`-z-20 absolute inset-x-0 top-0 h-screen ${
          thick ? 'pt-header-mobile-thick' : 'pt-header-mobile'
        } pointer-events-none transition-box duration-300 | lg:hidden`"
      >
        <div class="h-full bg-white pointer-events-auto overflow-y-auto">
          <form :action="pages.search.url" class="relative flex h-12 border-b border-gray-300">
            <span
              class="absolute left-0 ml-1 inset-y-0 flex justify-center items-center w-8 pointer-events-none"
            >
              <IconSearch class="w-5 h-5" />
            </span>
            <input
              type="text"
              name="searchTerm"
              placeholder="Search"
              aria-label="Search"
              class="flex-1 pl-9"
            />
          </form>
          <nav>
            <div class="flex h-12 border-b border-gray-300 divide-x divide-gray-300">
              <a
                :href="loggedIn ? '/account' : pages.login.url"
                class="relative flex items-center min-w-0 flex-1 px-4 hover:bg-gray-200 transition-colors duration-300"
              >
                <!-- <span class="absolute left-0 ml-1 inset-y-0 flex justify-center items-center w-8">
                  <IconAccount class="w-4 h-4" />
                </span> -->
                <span class="truncate">{{
                  loggedIn ? pages.account.title : pages.login.title
                }}</span>
              </a>

              <!--
              <a
                :href="pages.projects.url"
                class="relative flex items-center min-w-0 flex-1 pl-9 pr-4 hover:bg-gray-200 transition-colors duration-300"
              >
                <span class="absolute left-0 ml-1 inset-y-0 flex justify-center items-center w-8">
                  <IconProjects class="w-4 h-4" />
                </span>
                <span class="truncate">{{ pages.projects.title }}</span>
              </a>
              -->
            </div>
            <!-- <div v-if="loggedIn" class="flex h-12 border-b border-gray-300 divide-x divide-gray-300">
              <a
                href="/account/saved-products"
                class="relative flex items-center min-w-0 flex-1 pl-9 pr-4 hover:bg-gray-200 transition-colors duration-300"
              >
                <span class="absolute left-0 ml-1 inset-y-0 flex justify-center items-center w-8">
                  <IconFolder class="w-3 h-3" />
                </span>
                <span class="truncate">Saved Products</span>
              </a>
            </div> -->
            <ul class="flex flex-col">
              <li v-for="navItem in primaryNavigation" :key="navItem.id">
                <div class="flex h-12 border-b border-gray-300">
                  <a
                    v-if="navItem.children.length === 0"
                    :href="navItem.url"
                    class="relative flex items-center min-w-0 flex-1 px-4 hover:bg-gray-200 transition-colors duration-300"
                  >
                    <span class="truncate">{{ navItem.title }}</span>
                  </a>
                  <button
                    v-else
                    type="button"
                    class="relative flex justify-between items-center min-w-0 flex-1 px-4 hover:bg-gray-200 transition-colors duration-300"
                    @click="mobileToggleSubnav(navItem)"
                  >
                    <span class="truncate">{{ navItem.title }}</span>
                    <span class="left-0 ml-1 inset-y-0 flex justify-center items-center w-8">
                      <IconMinus v-if="mobileSubnavIsOpen(navItem)" class="w-3 h-3" />
                      <IconPlus v-else class="w-3 h-3" />
                    </span>
                  </button>
                </div>
                <ul
                  v-if="navItem.children.length > 0 && mobileSubnavIsOpen(navItem)"
                  class="border-b border-gray-300 bg-gray-200"
                >
                  <li v-for="subnavItem in navItem.children" :key="subnavItem.id">
                    <div
                      v-if="subnavItem.children.length > 0"
                      :class="`${
                        mobileSubnavIsOpen(subnavItem) ? '' : 'border-b border-gray-300'
                      } w-full`"
                    >
                      <button
                        v-if="subnavItem.type !== 'group'"
                        type="button"
                        :class="`relative w-full flex justify-between items-center min-w-0 flex-1 px-4 ${
                          mobileSubnavIsOpen(subnavItem) ? 'pt-4' : 'py-4'
                        } hover:bg-gray-200 transition-colors duration-300`"
                        @click="mobileToggleSubnav(subnavItem)"
                      >
                        <span class="truncate">{{ subnavItem.title }}</span>
                        <span class="left-0 ml-1 inset-y-0 flex justify-center items-center w-8">
                          <IconMinus v-if="mobileSubnavIsOpen(subnavItem)" class="w-3 h-3" />
                          <IconPlus v-else class="w-3 h-3" />
                        </span>
                      </button>
                      <ul
                        v-if="
                          subnavItem.children.length > 0 &&
                          (mobileSubnavIsOpen(subnavItem) || subnavItem.type === 'group')
                        "
                        :class="`${
                          subnavItem.type === 'group' ? 'bg-gray-200' : 'mt-4 bg-gray-150'
                        }`"
                      >
                        <li
                          v-for="(subnavInnerItem, subnavInnerItemIndex) in subnavItem.children"
                          :key="subnavInnerItem.id"
                          :class="`flex w-full py-4 border-b border-gray-300 ${
                            subnavInnerItemIndex === 0 ? 'border-t' : ''
                          } ${subnavItem.type === 'group' ? 'px-4' : 'px-8'}`"
                        >
                          <a :href="subnavInnerItem.url">
                            <span
                              class="truncate"
                              :class="subnavInnerItem.classes"
                              @click="showPopup"
                              >{{ subnavInnerItem.title }}</span
                            >
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div v-else class="w-full border-b border-gray-300">
                      <a
                        :href="subnavItem.url"
                        class="relative w-full flex justify-between items-center min-w-0 flex-1 py-4 px-4"
                        >{{ subnavItem.title }}</a
                      >
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <!-- Space for the global notice -->
          <div
            :class="`${
              thick ? 'h-header-mobile-thick' : 'h-header-mobile'
            } transition-box duration-300`"
          ></div>
        </div>
      </div>

      <!-- Desktop primary navigation -->
      <nav
        aria-label="Primary navigation"
        class="hidden | lg:block mt-auto custom--width"
        :class="`${thick ? 'mb-7' : 'mb-6'}`"
      >
        <ul class="flex">
          <li
            v-for="(navItem, idx) in primaryNavigation"
            ref="navParentItem"
            :id="navItem.id"
            :key="navItem.id"
            v-click-outside="() => desktopUnsetActiveNavItem(navItem)"
            :class="`${idx === primaryNavigation.length - 1 ? '' : 'xl:mr-10 mr-8'}`"
            @mouseenter="desktopSetActiveNavItem(navItem, 'hover', false, idx)"
            @mousedown="desktopSetActiveNavItem(navItem, 'hover', true, idx)"
            @mouseleave="desktopUnsetActiveNavItem(navItem, 'hover', false, idx)"
            @focusin="desktopSetActiveNavItem(navItem, 'focus', false, idx)"
            @focusout="desktopUnsetActiveNavItem(navItem, 'focus', false, idx)"
          >
            <a
              :href="navItem.url ? navItem.url : 'javascript:void(0)'"
              tabindex="0"
              :class="`font-semibold flex flex-row items-center uppercase tracking-wide mainNavLink ${
                navItem.id === desktopActiveNavItemId ? 'activeLink' : ''
              } ${navItem.highlight ? 'text-red-700' : ''} transition-colors duration-300 ${
                navItem.url ? '' : 'cursor-default'
              } ${navItem.children.length > 0 ? 'pr-0 linkWithArrow' : 'pr-0'}`"
              :data-cy="navItem.id === desktopActiveNavItemId ? 'active' : ''"
            >
              {{ navItem.title }}
            </a>
            <transition name="subnav">
              <div
                v-if="navItem.children.length > 0 && navItem.id === desktopActiveNavItemId"
                :class="`-z-20 absolute inset-x-0 top-0 ${
                  thick ? 'mt-header-desktop-thick' : 'mt-header-desktop'
                } px-6 pb-9 border-b border-gray-300 bg-white transition-box duration-300`"
              >
                <ul
                  class="-mx-0 md:-mx-5 xl:-mx-6 flex subnav-desktop"
                  :class="navItem.classes"
                  :style="{ 'padding-left': leftOffset + 'px' }"
                  :id="`${leftOffset === 0 ? 'justify--center' : ''}`"
                >
                  <li
                    v-for="(subnavColumn, subnavColumnIndex) in navItem.children"
                    :key="subnavColumn.id"
                    class="pt-10 px-0"
                    :class="`${
                      navItem.classes === 'long--menu'
                        ? 'flex-0 whitespace-nowrap'
                        : 'flex-1 max-w-50'
                    } ${subnavColumn.type === 'group' ? 'mr-12' : ''}`"
                  >
                    <ul
                      :class="`flex flex-col ${
                        subnavColumn.type === 'group' && navItem.children.length > 1
                          ? 'h-full border-r'
                          : subnavColumnIndex === 1
                          ? ''
                          : ''
                      }`"
                    >
                      <li v-if="subnavColumn.type !== 'group'">
                        <a
                          :href="subnavColumn.url"
                          :class="`block px-3 py-6px rounded-full mb-2 ${
                            subnavColumn.url ? 'hover:text-red-700' : ''
                          } ${subnavColumn.highlight ? 'text-red-700' : ''} ${
                            subnavColumn.classes
                          } text-xs font-semibold uppercase tracking-wide transition-colors duration-300`"
                        >
                          {{ subnavColumn.title }}
                        </a>
                      </li>
                      <li
                        v-for="(subnavItem, idx) in subnavColumn.children"
                        v-show="subnavItem.title !== 'Passive'"
                        :key="subnavItem.id"
                        :class="`${
                          subnavColumn.type === 'group' && idx !== subnavColumn.children.length - 1
                            ? 'mb-3'
                            : 'mb-0'
                        }`"
                      >
                        <!--<a
                          :href="subnavItem.url"
                          :target="`${ subnavItem.newWindow ? '_blank' : '' }`"
                          :class="`${
                            subnavItem.highlight
                              ? 'text-red-700'
                              : subnavColumn.type === 'group'
                              ? 'font-bold uppercase'
                              : ''
                          } ${subnavItem.classes} block px-3 py-6px rounded-full hover:text-red-700 transition-colors duration-300`"
                        >
                          {{ subnavItem.title }}
                        </a>-->
                        <a
                          :href="subnavItem.url"
                          @click="showPopup"
                          :class="`${
                            subnavItem.highlight
                              ? 'text-red-700'
                              : subnavColumn.type === 'group'
                              ? 'font-bold uppercase text-xs'
                              : 'text-sm'
                          } ${
                            subnavItem.classes
                          } block px-3 py-6px rounded-full hover:text-red-700 transition-colors duration-300`"
                        >
                          {{ subnavItem.title }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </transition>
          </li>
        </ul>
      </nav>

      <!-- Desktop utility navigation -->
      <nav
        aria-label="Utility navigation"
        class="hidden | lg:block -mr-3 mt-auto flex-1"
        :class="`${thick ? 'mb-6' : 'mb-5'}`"
      >
        <ul class="flex justify-end xl:space-x-6 space-x-3 pr-1">
          <li class="flex flex-row items-center">
            <button
              ref="desktopSearchButtonRef"
              type="button"
              aria-label="Search"
              class="flex justify-center items-center w-9 h-9 z-50 rounded-full hover:bg-gray-200 transition-colors duration-300"
              @click="openSearch"
            >
              <IconSearch class="w-5 h-5" />
            </button>
          </li>
          <!-- <li v-if="loggedIn">
            <a
              href="/account/saved-products"
              aria-label="Saved Products"
              class="flex justify-center items-center w-9 h-9 rounded-full hover:bg-gray-200 transition-colors duration-300"
            >
              <IconFolder class="w-5 h-5" />
            </a>
          </li> -->
          <li>
            <a
              :href="loggedIn ? '/account' : pages.login.url"
              :aria-label="loggedIn ? pages.account.title : pages.login.title"
              class="flex justify-center items-center w-9 h-9 rounded-full hover:bg-gray-200 transition-colors duration-300"
            >
              <IconAccount class="w-5 h-5" />
            </a>
          </li>
          <!--
          <li>
            <a
              :href="pages.projects.url"
              :aria-label="pages.projects.title"
              class="flex justify-center items-center w-9 h-9 rounded-full hover:bg-gray-200 transition-colors duration-300"
            >
              <IconProjects class="w-5 h-5" />
            </a>
          </li>
          -->
          <li>
            <a
              :href="pages.cart.url"
              :aria-label="pages.cart.title"
              class="flex justify-center items-center w-9 h-9 rounded-full hover:bg-gray-200 transition-colors duration-300"
            >
              <IconCart />
            </a>
          </li>
        </ul>
      </nav>

      <LegacySearch
        :search-open="searchOpen"
        :term="term"
        :thick="thick"
        @close-search="toggleSearch"
      >
        <template v-slot:icon-search>
          <IconSearch />
        </template>
      </LegacySearch>
    </div>
  </header>
</template>

<style scoped lang="postcss">
  .long--menu li {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }

  .long--menu li ul li {
    margin-right: 0;
    margin-left: 0;
  }
  @media (max-width: 1480px) {
    .long--menu li {
      margin-right: 0.625rem;
      margin-left: 0.625rem;
    }
  }
  @media (max-width: 1024px) {
    .long--menu li {
      margin-right: 0;
      margin-left: 0;
    }
  }

  #justify--center {
    justify-content: center;
    @media (max-width: 1280px) {
      justify-content: flex-start;
    }
  }

  .header--search {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    &.active {
      height: 100%;
      opacity: 1;
    }

    input {
      position: relative;
      outline: none;
    }

    button {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .header--search--close {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 10;
    cursor: pointer;
    transform: translateY(-50%);
  }

  .custom--width {
    width: calc(100% - 360px);
  }

  .subnav-desktop li.additionalItem {
    position: relative;
    min-width: 700px;
    max-width: 700px;

    &__child {
      min-width: 300px;
      max-width: 300px;

      &__link {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 50;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    @media (max-width: 1200px) {
      padding-left: 0;
      border-left: none;
    }
  }

  .additionalItem__image {
    width: 100%;
    height: 180px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .mainNavLink {
    position: relative;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1;

    svg {
      transition: all 0.25s ease-in-out;
      transform: rotate(0);
    }

    &.linkWithArrow {
      &.activeLink {
        &::after {
          width: 100%;
        }
      }
    }

    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 1px;
      content: '';
      background: #000;
      transition: all 0.25s ease-in-out;
    }

    &.text-red-700 {
      i {
        &::after {
          display: none;
        }
      }
    }

    &.activeLink {
      &::after {
        width: 100%;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .subnav {
    &-enter-active,
    &-leave-active {
      @apply transform transition duration-300;
    }

    &-leave-active {
      @apply pointer-events-none;
    }

    &-enter {
      @apply opacity-0 -translate-y-4;
    }

    &-leave-to {
      @apply opacity-0;
    }
  }

  .subnav-desktop {
    flex-wrap: wrap;
    @media (max-width: 1280px) {
      &.long--menu {
        justify-content: flex-start;
      }
    }
  }
</style>
