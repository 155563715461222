<script>
  import pinIcon from '@/images/icons/pin.svg'
  /** A Reusable TextRow component that can be used in content builder and standalone templates */

  export default {
    name: 'NewBuilderImageWithLocations',
    components: {
      pinIcon,
    },
    props: {
      /** The heading for the block, if defined */
      title: {
        type: String,
        required: true,
        default: '',
      },
      text: {
        type: String,
        required: true,
        default: '',
      },
      linkText: {
        type: String,
        default: '',
      },
      link: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      textAlign: {
        type: Boolean,
        default: false,
      },
      imageBlocks: {
        type: Array,
        required: false,
        default: () => [],
      },
    },
    computed: {
      resizedImage() {
        return this.bannerImage.urls[0]
      },
    },
  }
</script>

<template>
  <div>
    <div ref="imageSection" class="w-full imageSections">
      <div
        class="lg:flex lg:flex-row h500 lg:-mx-5"
        :class="{ 'lg:flex-row-reverse lg:space-x-reverse': textAlign }"
      >
        <div class="w-full lg:w-8/12" :class="{ 'lg:px-5': imageBlocks.length === 1 }">
          <div
            class="w-full lg:flex lg:flex-row"
            :class="{ 'space-y-10 lg:space-y-0': imageBlocks.length > 1 }"
          >
            <div
              v-for="(block, idx) in imageBlocks"
              :key="idx"
              class="w-full h-full hoverEffect"
              :class="{ 'lg:w-6/12 lg:px-5': imageBlocks.length > 1 }"
            >
              <div class="h500 w-full imgRepeatWrapper relative">
                <a
                  v-if="block.locationUrl" 
                  :href="block.locationUrl"
                  class="flex items-center justify-center w-full h-full top-0 left-0 absolute imgRepeatWrapper--link"
                >
                  <span>View Showroom</span>
                </a>
                <img
                  v-if="imageBlocks.length === 1"
                  class="w-full h-full"
                  :src="block.fullImage.urls[0]"
                  :alt="block.fullImage.alt"
                />
                <img
                  v-else
                  class="w-full h-full"
                  :src="block.shortImage.urls[0]"
                  :alt="block.shortImage.alt"
                />
              </div>
              <h4
                v-if="block.blockTitle"
                class="uppercase tracking-wide font-semibold mt-4 mb-2 text-lg locationLink"
                >
                <a :href="`${ block.locationUrl ? block.locationUrl : '#' }`">{{ block.blockTitle }}</a>
              </h4>
              <a
                v-if="block.locationUrl"
                :href="block.locationUrl"
                class="flex flex-row items-center locationLink"
              >
                <span>{{ block.location }}</span>
                <pinIcon width="14px" height="20px" class="ml-4 -mt-2" />
              </a>
            </div>
          </div>
        </div>
        <div class="w-full pt-8 lg:pt-0 lg:w-4/12 textWrapper">
          <h2 v-if="title" class="font-medium mb-4 lg:mb-0 tracking-wide lg:px-5">{{ title }}</h2>
          <div v-if="text" class="bottomText lg:px-5">
            <div class="flex flex-col space-y-4" v-html="text"></div>
            <!--<a 
                v-if="link" 
                :href="link.url"
                  class="w-full flex justify-center md:inline py-5 px-20 | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
            >
                <span v-if="linkText">{{ linkText }}</span>
                <span v-else>{{ link.title }}</span>
            </a>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .h500 {
    height: 500px;
  }
  .hoverEffect:hover {
    .imgRepeatWrapper--link {
      background: rgba(0,0,0,0.7);
      span {
        transform: scale(1);
      }
    }
    .locationLink {
      color: #CA1818;
    }
  }
  .imgRepeatWrapper--link {
    transition: all 0.25s ease-in-out;
    span {
      color: #fff;
      font-size: 24px;
      line-height: 1;
      font-weight: 500;
      transform: scale(0);
      transition: all 0.25s ease-in-out;
    }
    &:hover {
      background: rgba(0,0,0,0.7);
      span {
        transform: scale(1);
      }
    }
  }

  .bottomPad {
    padding-bottom: 16px;
  }

  h1 {
    font-size: 80px;
    line-height: 96px;
  }

  .imageSections {
    padding-top: 160px;
    padding-bottom: 60px;

    img {
      object-fit: cover;
    }
    @media (max-width: 1024px) {
      padding-top: 60px;
      padding-bottom: 0;
    }
  }
  .locationLink:hover {
    color: #CA1818;
  }

  .imageWrapper {
    width: 100%;
    height: 600px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .imgRepeatWrapper {
    top: 0;
    transition: all 0.25s ease-in-out;
    &:hover {
      top: 0;
      box-shadow: 0 0 12px rgba(0,0,0,0.2);
    }
  }

  .textWrapper {
    position: relative;

    h2 {
      display: block;
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 43px;
    }

    .bottomText {
      width: 100%;

      p {
        display: block;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .h500.imgRepeatWrapper {
      height: 450px;
    }

    .h500 {
      height: auto;
    }

    .textWrapper {
      h2 {
        position: static;
        margin-bottom: 20px;
      }

      .bottomText {
        position: static;
      }
    }

    .bottomPad {
      padding-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    .h500.imgRepeatWrapper {
      height: 320px;
    }

    .textWrapper {
      h2 {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
</style>
