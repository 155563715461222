<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseTileGridLoader from '@/components/BaseTileGridLoader'
  import BrandTile from '@/components/brands/BrandTile'
  import BrandPagination from '@/components/brands/BrandPagination'

  const { mapGetters, mapState } = createNamespacedHelpers('brands')
  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')

  /** Displays brands in a tile grid with pagination. Makes use of the brand tile grid and pagination
   * components as well as the base tile grid loader component */

  export default {
    name: 'BrandTileGrid',

    components: {
      BaseTileGridLoader,
      BrandTile,
      BrandPagination,
    },

    props: {
      tradeBrands: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        loader: {
          width: 0,
          height: 0,
        },
      }
    },

    computed: {
      /** Vuex getter methods */
      ...mapGlobalGetters(['isTradeAccount']),
      ...mapGetters(['getPaginatedItems', 'getIsLoading']),
      /** Returns the loaders emitted height */
      loaderHeight: function () {
        return this.loader.height
      },
      setPaginatedItems() {
        let sortedItems = []
        if (!this.isTradeAccount) {
          sortedItems = this.getPaginatedItems.filter(
            (item) => !this.tradeBrands.includes(item.title)
          )
        } else {
          sortedItems = this.getPaginatedItems
        }
        return sortedItems
      },
      /** Create the loader CSS style height string */
      loaderStyles: function () {
        return 'height:' + this.loaderHeight + 'px;'
      },
    },

    methods: {
      /** Sets the loader components height */
      setLoaderHeight(loader) {
        this.loader.height = loader.height
      },
    },
  }
</script>

<template>
  <div
    class="relative py-4 | md:py-6"
    :style="getIsLoading ? 'height: ' + (loaderHeight + 48) + 'px' : ''"
  >
    <transition name="fade">
      <div v-if="getIsLoading" class="absolute z-10 || loader" :style="loaderStyles">
        <BaseTileGridLoader @resize="setLoaderHeight" />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="!getIsLoading">
        <section v-if="getPaginatedItems.length">
          <div
            class="grid grid-cols-2 gap-4 | md:grid-cols-3 md:gap-6 | lg:grid-cols-4 | 2xl:grid-cols-5"
            role="feed"
          >
            <BrandTile v-for="item in setPaginatedItems" :key="item.id" v-bind="item" />
          </div>
          <BrandPagination class="mt-4 | md:mt-6" />
        </section>

        <div v-else class="p-4 text-center | md:p-6">
          <h3 class="mb-2 font-bold text-lg">No Brands Found</h3>
          <p>Please try again by widening your search and filter settings.</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="postcss">
  .loader {
    top: 16px;
    right: 16px;
    left: 16px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .loader {
      top: 24px;
      right: 24px;
      left: 24px;
    }
  }
</style>
