import { render, staticRenderFns } from "./BuilderTextRow.vue?vue&type=template&id=28ab33f5&scoped=true&"
import script from "./BuilderTextRow.vue?vue&type=script&lang=js&"
export * from "./BuilderTextRow.vue?vue&type=script&lang=js&"
import style0 from "./BuilderTextRow.vue?vue&type=style&index=0&id=28ab33f5&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ab33f5",
  null
  
)

export default component.exports