<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { eventBus } from '@/main.js'
  import api from '@/api'
  import BaseInputText from '@/components/BaseInputText'
  import BaseDateTimePicker from '@/components/BaseDateTimePicker'

  export default {
    name: 'MakeAnAppointmentForm',
    components: { BaseInputText, ValidationProvider, ValidationObserver, BaseDateTimePicker },
    props: {
      formObj: {
        type: Object,
        required: true,
      },
      formFields: {
        type: Object,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      bannerTitle: {
        type: String,
        default: '',
      },
      period: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        error: '',
        stateDisabled: false,
        isSending: false,
        isSent: false,
        errorMsg: '',
        success: null,
        submitCount: 0,
        location: '',
        dateTime: '',
        form: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          honeypot: '',
        },
        isVisible: false,
        hideAfterSubmit: true,
      }
    },
    created() {
      eventBus.$on('showFormOnScroll', (data) => {
        this.isVisible = data
      })
    },
    // watch:{
    //   dateTime() {
    //     console.log(this.dateTime)
    //   }
    // },
    computed: {
      getPathName() {
        let sentFrom = ''
        const url = new URL(window.location)
        const getPath = url.pathname.split('/').join('').split('-').join('')
        if (getPath.indexOf('newyork') !== -1) {
          this.location = 'NYC'
          sentFrom = 'Submitted from New York Showroom'
        } else if (getPath.indexOf('chicago') !== -1) {
          this.location = 'Chicago'
          sentFrom = 'Submitted from Chicago Showroom'
        }
        return sentFrom
      },
      getCurrentUserTimeZone() {
        const timeZoneInfo = Intl.DateTimeFormat().resolvedOptions().timeZone
        console.log(timeZoneInfo)
        return timeZoneInfo
      },
    },
    methods: {
      async handleFormSubmission() {
        this.isSending = true

        const formData = new FormData()

        const params = {
          formHash: this.formObj.hash,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          phone: this.form.phone,
          preferredAppointment: this.dateTime,
          sentFromShowroom: this.getPathName,
          senderTimeZone: this.getCurrentUserTimeZone,
        }

        if (this.formObj.honeypot) {
          params[this.formObj.honeypot.name] = this.formObj.honeypot.hash
        }

        for (const param in params) {
          formData.append(param, params[param])
        }

        if ((await this.$refs.makeAnAppointmentForm.validate()) !== true) {
          this.isSending = false
          this.errorMsg = 'Please fill out all required fields.'
          return
        }

        if (this.form.honeypot !== '') {
          this.isSending = false
          this.errorMsg = 'You are a bot.'
          return
        }

        this.errorMsg = ''

        api
          .post('freeform/submit', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-Requested-With': 'XMLHttpRequest',
            },
          })
          .then((response) => {
            if (response.status !== 200) {
              this.errorMsg = 'Oops! Something went wrong. Please try again.'
              this.success = false
            } else if (!response.data.success) {
              this.submitCount++
              this.success = false
              this.isSending = false

              // Prevent Form has Expired error

              if (this.submitCount < 2) {
                this.handleFormSubmission()
              } else {
                const errorHandles = Object.keys(response.data.errors)
                if (errorHandles.length > 0) {
                  errorHandles.forEach((name) => {
                    this.errorMsg =
                      response.data.errors[name][0] || response.data.formErrors[name][0]
                  })
                }
              }
            } else {
              api
                .post('/klaviyoconnect/api/track', {
                  event: {
                    name: 'Make An Appointment',
                  },
                  list: 'RRrbrf',
                  email: this.form.email,
                })
                .then((klaviyoRes) => {
                  if (klaviyoRes.status === 200) {
                    this.isSending = false
                    this.isSent = true
                    this.success = true
                    this.hideAfterSubmit = false
                    localStorage.setItem('name', this.form.firstName)
                    localStorage.setItem('location', this.location)
                    window.location.href = '/appointment/thanks'
                  } else if (res.status !== 200) {
                    this.error = 'Oops! Something went wrong. Please try again.'
                  }
                })
                .catch(() => {
                  this.error = 'Oops! Something went wrong. Please try again.'
                })
            }
          })
      },
    },
  }
</script>

<template>
  <div
    v-if="showForm"
    id="tradeAccountForm"
    class="py-6 md:py-20 lg:py-20 flex-col xl:flex-row justify-between relative"
    :class="`${isVisible ? 'block' : 'hidden'}`"
  >
    <div v-if="hideAfterSubmit" class="w-full flex flex-row justify-end">
      <div class="w-full lg:w-8/12 lg:flex lg:flex-row lg:space-x-16 lg:pl-4">
        <div class="formInnerWrapper">
          <div v-if="error" class="mb-4">
            <BaseError v-if="error" :error="error" class="text-center" />
          </div>
          <ValidationObserver ref="makeAnAppointmentForm">
            <form
              id="make-an-appointment-form"
              method="post"
              accept-charset="UTF-8"
              enctype="multipart/form-data"
              @submit.prevent="handleFormSubmission"
            >
              <input type="text" id="honeypot" v-model="form.honeypot" style="display: none" />
              <div class="mb-8 md:grid grid-cols-2 gap-12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="formFields.firstName.label"
                  rules="required"
                >
                  <BaseInputText
                    v-model="form.firstName"
                    :value="form.firstName"
                    :label="formFields.firstName.label + ' *'"
                    type="text"
                    :name="formFields.firstName.handle"
                    class="mb-4 md:mb-0"
                  />
                  <BaseError v-if="errors.length" :error="errors[0]" />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  :name="formFields.lastName.label"
                  rules="required"
                >
                  <BaseInputText
                    v-model="form.lastName"
                    :value="form.lastName"
                    :label="formFields.lastName.label + ' *'"
                    type="text"
                    :name="formFields.lastName.handle"
                  />
                  <BaseError v-if="errors.length" :error="errors[0]" />
                </ValidationProvider>
              </div>

              <div class="mb-8 md:grid grid-cols-2 gap-12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="formFields.email.label"
                  rules="required|email"
                >
                  <BaseInputText
                    v-model="form.email"
                    :value="form.email"
                    :label="formFields.email.label + ' *'"
                    type="email"
                    :name="formFields.email.handle"
                  />
                  <BaseError v-if="errors.length" :error="errors[0]" />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  :name="formFields.phone.label"
                  rules="required"
                >
                  <BaseInputText
                    v-model="form.phone"
                    :value="form.phone"
                    :label="formFields.phone.label + ' *'"
                    type="text"
                    :name="formFields.phone.handle"
                  />
                  <BaseError v-if="errors.length" :error="errors[0]" />
                </ValidationProvider>
              </div>

              <div class="mb-8 md:grid grid-cols-2 gap-12">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="formFields.date.label"
                  rules="required"
                >
                  <BaseDateTimePicker
                    format="MM/DD/YYYY h:i"
                    width="100%"
                    v-model="dateTime"
                    :value="dateTime"
                    :placeholder="formFields.date.label + ' *'"
                    :name="formFields.date.handle"
                  ></BaseDateTimePicker>
                  <BaseError v-if="errors.length" :error="errors[0]" />
                </ValidationProvider>
              </div>

              <div>
                <BaseButton capitalize type="submit" color="black" :disabled="isSending">{{
                  isSending ? 'Sending' : 'Submit'
                }}</BaseButton>

                <div v-if="error || errorMsg">
                  <BaseError
                    v-if="error || errorMsg"
                    :error="errorMsg ? errorMsg : error"
                    class="text-center absolute mt-4 text-sm"
                  />
                </div>
              </div>
            </form>
          </ValidationObserver>
          <div class="formDisclaymer">
            <p>
              * All appointment requests must be confirmed by one of our design associates. <br />
              We are closed on Saturdays and Sundays.
            </p>
          </div>
        </div>
      </div>
    </div>
    <p v-if="success" class="uppercase text-green-600 text-center mt-4 text-lg">
      Form has submitted successfully!
    </p>
  </div>
</template>

<style lang="scss" scoped>
  .formDisclaymer {
    width: 100%;
    padding-top: 40px;
    font-weight: 500;

    p {
      font-size: 15px;
      line-height: 20px;
      color: #000;
      text-indent: -11px;
    }
  }

  .formInnerWrapper {
    width: 700px;
  }
  @media (max-width: 1024px) {
    .formInnerWrapper {
      width: 100%;
      padding-bottom: 80px;
    }
  }
</style>
