<script>
  import { trimHtml, insertIntoLastElement } from '@/utilities/strings'

  /** The product text description component. Displays truncated summary HTML text when
   * the text is too long, and allows for toggling to the full text. */

  export default {
    name: 'ProductDescription',

    props: {
      /** The actual description */
      text: {
        type: String,
        required: false,
        default: '',
      },
      /** For the collection line */
      productTitle: {
        type: String,
        required: false,
        default: '',
      },
      /** The collection name this product belongs to */
      collectionName: {
        type: String,
        required: false,
        default: '',
      },
    },

    data() {
      return {
        isOpen: false,
      }
    },

    computed: {
      /** Generates a URL or collections */
      collectionLink: function () {
        const noWhitespace = this.collectionName.replace(/\s/g, '+')
        return '/search/?collection=' + noWhitespace
      },
      /** The full text content + collection link */
      fullContent: function () {
        let addedContent = ''
        if (this.collectionName && this.productTitle) {
          addedContent = `<p class="mt-4">
            The ${this.productTitle} is part of the
            <a href="${this.collectionLink}">${this.collectionName} collection.</a>
          </p>`
        }
        return this.text + addedContent
      },
      /** The text content trimmed down for the summary */
      trimmedContent: function () {
        return trimHtml(this.fullContent, { limit: 400 })
      },
      /** The full HTML text with indicator toggle added inline */
      fullText: function () {
        if (this.hasMore) {
          const addedContent =
            '<span class="relative inline-block ml-1 text-gray-500 | content-clamp-toggle"> - </span>'
          return insertIntoLastElement(this.fullContent, addedContent)
        } else {
          return this.fullContent
        }
      },
      /** The trimmed HTML text with indicator toggle added inline */
      trimmedText: function () {
        const addedContent =
          '<span class="relative inline-block ml-1 text-gray-500 | content-clamp-toggle"> + </span>'
        return insertIntoLastElement(this.trimmedContent.html, addedContent)
      },
      /** Returns a boolean of true if there is more text than the trimmed summary */
      hasMore: function () {
        return this.trimmedContent.more
      },
      /** Aria label for toggle button */
      toggleLabel: function () {
        return this.isOpen ? 'Collapse text' : 'Expand text'
      },
    },

    mounted() {
      /** On mount prevent any links in the content areas from propagating a click */
      const contentLinks = this.$refs.content.querySelectorAll('a')
      for (let i = 0; i < contentLinks.length; i++) {
        contentLinks[i].addEventListener('click', (event) => {
          event.stopPropagation()
        })
      }
    },

    methods: {
      /** Toggles the summary and full text */
      toggleClamp() {
        this.isOpen = !this.isOpen
      },
    },
  }
</script>

<template>
  <div v-if="text">
    <button
      v-show="hasMore"
      ref="toggle"
      type="button"
      class="text-left text-black | focus:outline-none || content-clamp"
      :aria-label="toggleLabel"
      @click="toggleClamp"
    >
      <div v-show="!isOpen" ref="content" class="wysiwyg" v-html="trimmedText"></div>
      <div v-show="isOpen" ref="content" class="wysiwyg" v-html="fullText"></div>
    </button>
    <div v-show="!hasMore" class="text-left">
      <div class="wysiwyg" v-html="fullText"></div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  .content-clamp {
    &:hover,
    &:focus {
      >>> .content-clamp-toggle {
        @apply underline;
      }
    }
  }
  /* purgecss end ignore */
</style>
