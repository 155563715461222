<script>
  import BuilderOffsetGalleryTile from '@/components/builder/BuilderOffsetGalleryTile'
  import { formatTimestamp } from '@/utilities/strings'

  /** An offset gallery that displays tiles left and right */

  export default {
    name: 'BuilderOffsetGallery',

    components: {
      BuilderOffsetGalleryTile,
    },

    props: {
      /** The heading above the gallery */
      heading: {
        type: String,
        required: false,
        default: null,
      },
      /** The summary above the gallery */
      summary: {
        type: String,
        required: false,
        default: null,
      },
      /** An array of tiles to include in the gallery */
      tiles: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** The label of the block section (used by TOC block) */
      label: {
        type: String,
        required: false,
        default: '',
      },
      columnsCount:{
        type:Boolean,
        default:false
      },
      /** An ID anchor of the block section (used by TOC block) */
      anchor: {
        type: String,
        required: false,
        default: '',
      },
      /** If this is the last child component in the parent content builder component */
      isLast: {
        type: Boolean,
        required: false,
      },
    },

    computed: {
      /** Gets the odd tiles for column one  */
      columnOneTiles: function () {
        const tileArr = []
        for (let i = 0; i < this.tiles.length; i++) {
          if (i % 2 === 0) {
            tileArr.push(this.tiles[i])
          }
        }
        return tileArr
      },
      /** Gets the even tiles for column two */
      columnTwoTiles: function () {
        const tileArr = []
        for (let i = 0; i < this.tiles.length; i++) {
          if (i % 2 !== 0) {
            tileArr.push(this.tiles[i])
          }
        }
        return tileArr
      },
    },

    methods: {
      /** Resolves the props from the different slide types so they can be mapped to the BuilderOffsetGalleryTile
       * component */
      resolveTileProps(tile, align = 'left') {
        const content = tile.content
        const propObj = {
          title: content.title,
          url: content.url,
          shape: content.shape,
          image: content.image,
          align: align,
        }
        switch (tile.type) {
          case 'Product':
            propObj.subheading = content.brand
            propObj.tags = content.tags
            break
          case 'Post':
            propObj.subheading =
              formatTimestamp(content.postDate, '.') +
              ' <span class="lowercase">x</span> ' +
              content.category
            break
          case 'Project':
            propObj.subheading = content.type + ' <span class="lowercase">x</span> ' + content.year
            break
          case 'Brand':
            propObj.subheading = content.country
            break
          case 'Designer':
            propObj.subheading = content.country
            break
          case 'Custom':
            propObj.subheading = content.subheading
            propObj.target = content.target
        }
        return propObj
      },
    },
  }
</script>

<template>
  <section
    v-if="tiles.length"
    :id="anchor"
    class="mt-16 lg:mt-20 || section"
    :aria-label="label"
  >
    <header v-if="heading || summary" class="mb-10 space-y-3">
      <h2 v-if="heading">{{ heading }}</h2>
      <div v-if="summary" class="text-black leading-relaxed mx-auto || summary" v-html="summary" />
    </header>

    <div class="flex flex-col space-y-12 | md:hidden">
      <BuilderOffsetGalleryTile
        v-for="(tile, index) in tiles"
        :key="index"
        v-bind="resolveTileProps(tile, 'left')"
      />
    </div>

    <div class="relative hidden | md:block">
      <div 
        class="flex flex-row"
        :class="`${ columnsCount ? 'flex-wrap -mx-2 lg:-mx-4' : 'space-x-3 lg:space-x-8' }`"
      >
        <BuilderOffsetGalleryTile
          v-for="(tile, index) in tiles"
          :key="index"
          :columns="columnsCount"
          v-bind="resolveTileProps(tile)"
          :class="`${ heading === 'Collaborations' ? 'more--height' : '' }`"
        />
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
  .summary {
    max-width: 350px;
  }

  .grid-layout {
    align-items: stretch;
    max-width: 1170px;

    &::after {
      left: 50%;
      width: 1px;
      content: '';
      @apply absolute top-0 bottom-0 block bg-gray-300;
    }
  }
</style>
