<script>
  export default {
    name: 'BaseViewToggle',

    props: {
      options: {
        type: Array,
        required: true,
      },
      value: {
        type: [String, Number],
        required: true,
      },
    },

    data() {
      return {
        selected: this.options[0].value,
      }
    },

    watch: {
      value: function (value) {
        this.isSelected(value)
      },
    },

    methods: {
      /** Match the current option's value against the v-model to see if it's selected. */
      isSelected(value) {
        return this.selected === value
      },
      /** Pass the option's value  to the parent */
      updateSelectedOption(value) {
        this.selected = value
        this.$emit('change', value)
        this.$refs.toggleButton.forEach(function (button) {
          button.blur()
        })
      },
    },
  }
</script>

<template>
  <div class="inline-flex space-x-4 | md:space-x-5">
    <button
      v-for="option in options"
      ref="toggleButton"
      :key="option.value"
      role="radio"
      :aria-checked="isSelected(option.value)"
      :class="{
        'text-black selected': isSelected(option.value),
        'text-gray-500': !isSelected(option.value),
      }"
      class="relative pr-3 transition-colors duration-300 | focus:outline-none focus:underline | md:pr-4 md:text-2xl"
      @click="updateSelectedOption(option.value)"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<style scoped lang="postcss">
  button {
    &::after {
      top: 8px;
      width: 6px;
      height: 6px;
      content: '';
      @apply absolute right-0 inline-block rounded-full bg-black opacity-0 transition-opacity duration-300;
    }

    &.selected::after {
      @apply opacity-100;
    }
  }

  @screen md {
    button::after {
      top: 8px;
      width: 8px;
      height: 8px;
    }
  }
</style>
