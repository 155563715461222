<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseTileGridLoader from '@/components/BaseTileGridLoader'
  import DesignerTile from '@/components/designers/DesignerTile'
  import DesignerPagination from '@/components/designers/DesignerPagination'

  const { mapGetters } = createNamespacedHelpers('designers')

  /** Displays designers in a tile grid with pagination. Makes use of the designer tile grid and pagination
   * components as well as the base tile grid loader component */

  export default {
    name: 'DesignerTileGrid',

    components: {
      BaseTileGridLoader,
      DesignerTile,
      DesignerPagination,
    },

    data() {
      return {
        loader: {
          width: 0,
          height: 0,
        },
      }
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getPaginatedItems', 'getIsLoading']),
      /** Returns the loaders emitted height */
      loaderHeight: function () {
        return this.loader.height
      },
      /** Create the loader CSS style height string */
      loaderStyles: function () {
        return 'height:' + this.loaderHeight + 'px;'
      },
    },

    methods: {
      /** Sets the loader components height */
      setLoaderHeight(loader) {
        this.loader.height = loader.height
      },
    },
  }
</script>

<template>
  <div
    class="relative py-4 | md:py-6"
    :style="getIsLoading ? 'height: ' + (loaderHeight + 48) + 'px' : ''"
  >
    <transition name="fade">
      <div v-if="getIsLoading" class="absolute z-10 || loader" :style="loaderStyles">
        <BaseTileGridLoader @resize="setLoaderHeight" />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="!getIsLoading">
        <section v-if="getPaginatedItems.length">
          <div
            class="grid grid-cols-2 gap-4 | md:grid-cols-3 md:gap-6 | lg:grid-cols-4 | 2xl:grid-cols-5"
            role="feed"
          >
            <DesignerTile v-for="item in getPaginatedItems" :key="item.id" v-bind="item" />
          </div>
          <DesignerPagination class="mt-4 | md:mt-6" />
        </section>

        <div v-else class="p-4 text-center | md:p-6">
          <h3 class="mb-2 font-bold text-lg">No Designers Found</h3>
          <p>Please try again by widening your search and filter settings.</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="postcss">
  .loader {
    top: 16px;
    right: 16px;
    left: 16px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .loader {
      top: 24px;
      right: 24px;
      left: 24px;
    }
  }
</style>
