<script>
  import IconAngleDown from '@/images/icons/chevron-down.svg'

  /** Accordion Base Component new. */

  export default {
    name: 'BaseAccordion',
    components: {
      IconAngleDown,
    },
    props: {
      index: {
        type: Number,
      },
    },
    data() {
      return {
        isOpen: false,
      }
    },
    methods: {
      toggleIcon() {
        this.isOpen = !this.isOpen
      },
    },
  }
</script>

<template>
  <div>
    <details :id="index" class="border-t border-gray-300 px-5">
      <summary class="w-full text-left outline-0">
        <span class="flex justify-between items-center w-full accordeon--title" @click="toggleIcon">
          <span class="max-w-4/5 md:max-w-full">
            <slot name="heading"></slot>
          </span>
          <IconAngleDown
            width="15px"
            height="15px"
            class="transform origin-center transition duration-300 icon-down"
            :class="{ 'rotate-180': isOpen }"
          />
        </span>
      </summary>
      <div :class="`accordion-content pb-2`">
        <slot name="content"></slot>
      </div>
    </details>
  </div>
</template>

<style scoped>
  summary::-webkit-details-marker {
    display: none;
  }

  .accordeon--title {
    padding: 18px 0;
  }

  summary {
    list-style: none;
    cursor: pointer;
    outline: none;
    /** set fallback focus styles */
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  details .filterRightSelect {
    width: auto;
    margin-left: 0;
  }

  .accordion-content {
    ul {
      @apply mt-0;
    }
  }
</style>
