<script>
  /** This is the view template for the Designer Detail Page. It mostly pulls in other templates. */
  import { mapGetters, mapActions } from 'vuex'
  import ProfileDescription from '@/components/profiles/ProfileDescription'
  import ProductFilter from '@/components/products/ProductFilter'
  import ProductTileGrid from '@/components/products/ProductTileGrid'
  import DesignerList from '@/components/designers/DesignerList'
  import FilterIcon from '@/images/icons/filter-icon.svg'
  import IconClose from '@/images/icons/clear-filters.svg'

  /** This is the main view template for the Designer Detail Page. It mostly pulls in other components. */

  export default {
    name: 'DesignerDetailPage',

    components: {
      ProfileDescription,
      ProductFilter,
      ProductTileGrid,
      DesignerList,
      FilterIcon,
      IconClose,
    },

    props: {
      /** The Craft designer entry slug */
      priceFilters: {
        type: Array,
        default: () => [],
      },
      designer: {
        type: String,
        required: true,
      },
      /** The designers name */
      title: {
        type: String,
        required: true,
      },
      /** The designers hero image */
      image: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** The designers city */
      city: {
        type: String,
        required: false,
        default: null,
      },
      /** The designers description/body */
      description: {
        type: String,
        required: false,
        default: null,
      },
      /** The designers website */
      website: {
        type: String,
        required: false,
        default: null,
      },
      /** An array of product IDs of curated products for this designer */
      curatedProducts: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** The options for the filters */
      filterOptions: {
        type: Object,
        default: null,
      },
      /** The handles of filters to hide */
      hiddenFilters: {
        type: Array,
        default: null,
      },
      /** An initial status filter to set on page load */
      initialFilter: {
        type: String,
        default: null,
        validator: (propValue) => {
          const validTypes = ['quickShip', 'inShowroom']
          return validTypes.indexOf(propValue) > -1
        },
      },
      /** Is the user logged in as a trade account user */
      isTradeAccount: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        selectedFilters: [],
        clearedFilter: [],
        filterBarOpen: false,
        moreWidth: true,
        topOffsets: 0,
        isCleared: 0,
        readmore: false,
        isVisible: false,
        giveOpacity: false,
      }
    },

    watch: {
      selectedFilters(item) {
        if (item.length === 0) {
          this.isVisible = false
        } else {
          this.isVisible = true
        }
      },
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters('designers', ['getTotalItems']),
      ...mapGetters('designers', ['getStructuredItems']),
      ...mapGetters('products', [
        'getSortOptions',
        'getSort',
        'getPaginationData',
        'getIsLoading',
        'getSelectedOption',
      ]),
      shownQuantity: function () {
        return (
          '<span data-cy="currentNumberOfProducts">' + this.getPaginationData.current + '</span>'
        )
      },

      totalQuantity: function () {
        return '<span data-cy="totalNumberOfProducts">' + this.getPaginationData.total + '</span>'
      },
    },

    created() {
      this.$store.dispatch('products/isRandomized', true)
      /** Initialize the filters */
      this.$store.dispatch('products/initFilters', {
        filterOptions: this.filterOptions,
        hiddenFilters: this.hiddenFilters,
      })

      this.$store.dispatch('products/initPriceFilters', this.priceFilters)

      /** If an initial filter is defined (either quickShip or inShowroom) set it */
      if (this.initialFilter) {
        this.$store.dispatch('products/setFilterValue', {
          filter: this.initialFilter,
          value: true,
        })
      }

      /** Dispatch actions in the Vuex store to get the products by category */
      this.$store.dispatch('products/initProducts', {
        designer: this.title,
        curated: this.curatedProducts,
        isTradeAccount: this.isTradeAccount,
      })

      /** Dispatch actions in the Vuex store to get all designers */
      this.$store.dispatch('designers/initItems')
    },

    mounted() {
      this.setSort(this.getSort)
    },

    methods: {
      newUpdateFilters(data) {
        this.selectedFilters = data
      },
      scrollToElementOnClick(target) {
        const element = document.querySelector(`#item-${target}`)
        if (element) {
          const noticeElement = document.querySelector('#notice')
          let noticeHeight = 0
          if (noticeElement !== null) {
            noticeHeight = document.querySelector('#notice').offsetHeight
          } else {
            noticeHeight = 0
          }
          const headerHeight = document.querySelector('#ref-header').offsetHeight
          const yOffset = headerHeight + noticeHeight + 20

          const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset
          window.scrollTo({ top: y - 62, behavior: 'smooth' })
        }
      },
      showSidebarFilters() {
        this.moreWidth = !this.moreWidth
        this.giveOpacity = true
      },
      ...mapActions('products', [
        'setSort',
        'clearFilters',
        'clearAllFilters',
        'remoteSelectedFilter',
      ]),
      ...mapGetters(['getUsedFilters']),
    },
  }
</script>

<template>
  <main id="main-content">
    <article class="pt-10">
      <!--<BasePageHeader :heading="title" :prefix="'Designer'" />-->

      <ProfileDescription
        :image="image"
        :city="city"
        :website="website"
        :heading="title"
        :textLength="description.length"
        @readmore="readmore = $event"
      >
        <div
          class="space-y-4"
          v-html="`${readmore ? description : description.substring(0, 900)}`"
        ></div>
      </ProfileDescription>

      <div ref="DesignerList" class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 mb-16">
        <div class="hidden appendFilters w-full lg:flex-row items-center lg:flex mb-2">
          <div
            class="filterHidden mr-12 flex flex-row flex-wrap items-center cursor-pointer"
            :class="`${!moreWidth ? 'ml-0 lg:ml-1' : ''}`"
            @click="showSidebarFilters"
          >
            <span class="mr-2 font-medium text-sm leading-tight"
              >{{ `${moreWidth ? 'Show' : 'Hide'}` }} Filters</span
            >
            <FilterIcon />
          </div>
          <span
            v-if="!getIsLoading"
            class="block text-center mr-8 text-sm text-gray-600 || mobile"
            aria-current="true"
            aria-label="current products"
            v-html="shownQuantity + ' items selected'"
          ></span>
          <div class="filterButtons">
            <ul>
              <li v-for="(filter, idx) in getSelectedOption" :key="idx">
                <span @click="remoteSelectedFilter(filter.index)">
                  {{ filter.label }}
                  <IconClose width="12px" height="12px" />
                </span>
              </li>
            </ul>
          </div>
          <button
            v-if="getSelectedOption.length > 0"
            class="font-normal text-base text-black underline hover:no-underline"
            :class="getUsedFilters.length === 0 ? 'text-black' : ''"
            @click="clearAllFilters()"
          >
            Clear all
          </button>
          <div class="hidden lg:flex justify-end rightFilter">
            <!-- Pagination indicator medium screens -->
            <!-- <nav
              ref="pagination"
              aria-label="Product pagination"
              class="hidden md:block mt-6 ml-6"
            >
              <span
                :class="!getIsLoading ? 'visible' : 'invisible'"
                class="md:text-sm lg:text-base whitespace-no-wrap text-gray-500 || mobile"
                aria-current="true"
                aria-label="current products"
                v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
              />
            </nav> -->
            <BaseFilterSelect
              label="Sort by"
              :options="getSortOptions"
              :value="getSort"
              class="lg:pr-6 mt-3"
              @change="setSort($event)"
            />
          </div>
        </div>
        <div>
          <div
            class="static flex flex-col md:flex-row md:relative flex-wrap overflow-hidden mb-12"
            :class="filterBarOpen ? 'z-99' : ''"
          >
            <!-- <div 
              v-if="moreWidth"
              @click="moreWidth = !moreWidth"
              class="filterActionBtn"
            >
              <span>+Filter</span>
            </div> -->
            <ProductFilter
              :type="'designers'"
              :open="filterBarOpen"
              :getIsLoading="getIsLoading"
              :giveOpacity="giveOpacity"
              @toggle="filterBarOpen = $event"
              @showSidebarFilters="showSidebarFilters"
              :moreWidth="moreWidth"
            />
            <div
              v-if="filterBarOpen"
              class="z-20 absolute inset-0 lg:hidden bg-black bg-opacity-25"
              @click="filterBarOpen = false"
            ></div>
            <div
              class="transition--all pt-1 md:pt-0"
              :class="`${moreWidth ? 'w-full' : 'w-full lg:w-9/12 xl:w-4/5'}`"
            >
              <!-- Pagination indicator small screens -->
              <!-- <nav ref="pagination" aria-label="Product pagination" class="block md:hidden mt-4">
                <span
                  v-if="!getIsLoading"
                  class="block text-center md:hidden text-sm text-gray-500 || mobile"
                  aria-current="true"
                  aria-label="current products"
                  v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
                />
              </nav> -->
              <ProductTileGrid
                class="md:pt-1"
                :moreWidth="moreWidth"
                :hide-price="true"
                :destination="'ProductsListingPage'"
                ref="ProductTileGrid"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto lg:max-w-screen-2xl w-full px-4 lg:px-6 alphaGrid">
        <div class="w-full alphaSection relative z-40 overflow-x-auto md:overflow-x-hidden">
          <ul
            class="flex flex-wrap flex-row w-full justify-start md:justify-between border-t border-b border-gray-300 py-5 headingList"
          >
            <li v-for="(item, x) in getStructuredItems" :key="x" class="mx-4 md:mx-0">
              <span
                class="font-semibold uppercase text-black block m-0 text-sm cursor-pointer hover:text-red-700"
                @click="scrollToElementOnClick(item.heading)"
                >{{ item.heading }}</span
              >
            </li>
          </ul>
        </div>
        <div class="pt-8 pb-20 lg:pt-14 lg:pb-40">
          <h4 class="flex justify-between items-center mb-8 text-2xl | md:block md:mb-12">
            <em class="not-italic">Explore Our Brands</em>
            <span v-if="getTotalItems" class="text-gray-500 | md:ml-2">{{ getTotalItems }}</span>
          </h4>

          <DesignerList />
        </div>
      </div>
    </article>
  </main>
</template>

<style scoped lang="postcss">
  .rightFilter {
    width: 210px;
    margin-left: auto;
  }

  .appendFilters {
    position: relative;
    top: 0;
    z-index: 99;
    width: 100%;
  }

  .filterButtons {
    display: flex;
    width: auto;
    max-width: 600px;
    margin-right: 20px;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -10px;

      li {
        margin-right: 10px;
        margin-bottom: 10px;

        span {
          position: relative;
          display: inline-flex;
          align-items: center;
          height: 26px;
          padding: 0 37px 0 15px;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          color: #000;
          cursor: pointer;
          border: 1px solid #000;

          svg {
            position: absolute;
            top: 50%;
            right: 18px;
            transform: translateY(-50%);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .alphaSection {
    &.fixed {
      position: fixed;
      left: 0;
      z-index: 999;
      background: #fff;

      ul {
        padding-right: 24px;
        padding-left: 24px;
      }

      &.bigFixed {
        top: 128px;
      }

      &.smallFixed {
        top: 72px;
      }
    }
    @media (max-width: 1024px) {
      &.fixed {
        &.bigFixed {
          top: 102px;
        }

        &.smallFixed {
          top: 56px;
        }
      }

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #6f6f6f;
        border-radius: 100px;
        outline: none;
      }
    }
    @media (max-width: 992px) {
      &.fixed {
        &.bigFixed {
          top: 80px;
        }
      }
    }
    @media (max-width: 767px) {
      &.fixed {
        ul {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }

  .headingList {
    li {
      span {
        font-family: 'Inter', sans-serif;
      }
    }
    @media (max-width: 767px) {
      width: 1000px;
    }
  }

  .filterActionBtn {
    position: absolute;
    top: 75px;
    left: -14px;
    z-index: 999;
    cursor: pointer;
    transform: rotate(-90deg);

    span {
      font: 500 14px/14px 'Inter', sans-serif;
      color: #000;
    }
  }

  .transition--all {
    transition: all 0.25s ease-in-out;
  }
  @media (max-width: 1024px) {
    .stickySidebar {
      margin-left: 0 !important;
    }
  }
</style>
