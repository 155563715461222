<script>
  import { trimHtml, insertIntoLastElement } from '@/utilities/strings'

  /** A Reusable TextRow component that can be used in content builder and standalone templates */

  export default {
    name: 'BuilderTextRow',
    props: {
      /** The heading for the block, if defined */
      heading: {
        type: String,
        required: false,
        default: '',
      },
      /** The text that the component will display */
      text: {
        type: String,
        default:''
      },
      /** Prop to set the component text alignment */
      align: {
        type: String,
        default: 'center',
        validator: (value) => {
          // The value must match one of these strings
          return ['center', 'left', 'right'].indexOf(value) !== -1
        },
      },
      /** Component label */
      label: {
        type: String,
        default: null,
      },
      /** Component anchor */
      anchor: {
        type: String,
        default: null,
      },
      /** Component border */
      hasBorder: {
        type: Boolean,
        default: false,
      },
      /** Whether or not to make this block expand/contract */
      collapsible: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        isOpen: false,
      }
    },

    computed: {
      /** Center horizontally using auto margins */
      center() {
        return this.align === 'center' ? 'mx-auto lg:max-w-2xl' : 'max-w-full'
      },
      /** The text content trimmed down for the summary */
      trimmedContent: function () {
        return trimHtml(this.text, { limit: 260 })
      },
      /** The full HTML text with indicator toggle added inline */
      fullText: function () {
        if (this.hasMore) {
          const addedContent =
            '<span class="relative inline-block ml-1 text-black | content-clamp-toggle"> - </span>'
          return insertIntoLastElement(this.text, addedContent)
        } else {
          return this.fullContent
        }
      },
      /** The trimmed HTML text with indicator toggle added inline */
      trimmedText: function () {
        const addedContent =
          '<span class="relative inline-block ml-1 text-black | content-clamp-toggle"> + </span>'
        return insertIntoLastElement(this.trimmedContent.html, addedContent)
      },
      /** Returns a boolean of true if there is more text than the trimmed summary */
      hasMore: function () {
        return this.trimmedContent.more
      },
      /** Aria label for toggle button */
      toggleLabel: function () {
        return this.isOpen ? 'Collapse text' : 'Expand text'
      },
    },

    mounted() {
      /** On mount prevent any links in the content areas from propagating a click */
      if (this.collapsable) {
        const contentLinks = this.$refs.content.querySelectorAll('a')
        for (let i = 0; i < contentLinks.length; i++) {
          contentLinks[i].addEventListener('click', (event) => {
            event.stopPropagation()
          })
        }
      }
    },
    
    methods: {
      /** Toggles the summary and full text */
      toggleClamp() {
        this.isOpen = !this.isOpen
      },
    },
  }
</script>

<template>
  <div
    v-if="text || heading"
    :id="anchor"
    class="border-gray-300 || section textRowSection"
    :class="{ 'border-b': hasBorder }"
    :aria-label="label"
  >
    <h2 v-if="heading" class="my-4">{{ heading }}</h2>

    <div v-if="collapsible">
      <button
        v-show="hasMore"
        ref="toggle"
        type="button"
        class="text-left | focus:outline-none || content-clamp"
        :aria-label="toggleLabel"
        @click="toggleClamp"
      >
        <div
          v-show="!isOpen"
          ref="content"
          class="wysiwyg text-xl"
          v-html="trimmedText"
        ></div>
        <div
          v-show="isOpen"
          ref="content"
          class="wysiwyg text-xl"
          v-html="fullText"
        ></div>
      </button>
      <div v-show="!hasMore" class="text-left">
        <div class="wysiwyg" v-html="fullText"></div>
      </div>
    </div>
    <div v-else>
      <div v-if="text" class="wysiwyg mb-8" v-html="text"></div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .text-row {
    max-width: 90%;
    font-size: 21px;
    line-height: 29.4px;

    a {
      border-bottom: 1px solid #000;
    }
  }

  .text-right,
  .text-left {
    max-width: 100%;
  }

  /* purgecss start ignore */
  .content-clamp {
    &:hover,
    &:focus {
      >>> .content-clamp-toggle {
        @apply underline;
      }
    }
  }
  /* purgecss end ignore */

  @screen md {
    .text-row {
      font-size: 24px;
    }
  }

  @screen lg {
    .text-row {
      max-width: 74%;
    }

    .text-right,
    .text-left {
      max-width: 100%;
    }
  }

  @screen xl {
    .text-row {
      max-width: 770px;
    }

    .text-right,
    .text-left {
      max-width: 100%;
    }
  }
</style>
