<script>
  export default {
    name: 'PreviewElement',

    props:{
      brandItem:{
        type: Object,
        default: () => {}
      }
    },
  }
</script>

<template>
  <div 
    class="w-3/12 pl-5 previewBrand"
    :style="[Object.keys(brandItem).length ? { 'border-left': '1px solid rgba(223, 223, 223, 0.7)' } : {}]"
    >
    <div
        class="previewBrandInner relative"
        :class="`${ Object.keys(brandItem).length > 0 ? 'active' : '' }`"
    >
        <div v-if="Object.keys(brandItem).length > 0">
        <a :href="brandItem.url" class="block w-full h-full absolute top-0 left-0 z-50"></a>
        <div class="w-full h-80 mb-5">
            <img :src="brandItem.image.urls[1]" :alt="brandItem.alt" class="w-full h-full object-cover">
        </div>
        <h4 class="block w-full mb-1 uppercase font-semibold text-xl">{{ brandItem.title }}</h4>
        <span class="text-gray-500 text-base block mb-3">{{ brandItem.country }}</span>
        <p>
            {{ brandItem.description }}
        </p>
        </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .previewBrandInner {
    opacity: 0;
    transition: all 0.35s ease-in-out;
    &.active {
      opacity: 1;
    }
  }
</style>
