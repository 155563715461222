<script>
  /** Builder Section heading */

  export default {
    name: 'BuilderHeading',

    props: {
      /** The text of the heading */
      heading: {
        type: String,
        required: true,
      },
      /** The tailwind font size */
      fontSize: {
        type: String,
        required: true,
      },
      /** The heading element */
      headingElement: {
        type: String,
        required: true,
      },
      /** The vertical padding */
      padding: {
        type: String,
        required: true,
      },
    },
    render: function (createElement) {
      return createElement(
        this.headingElement, // tag name
        {
          class: `pt-2 pb-4 font-medium`,
        },
        this.heading
      )
    },
  }
</script>

<style lang="postcss" scoped>
  h3 {
    @apply font-medium;
    @apply text-xl;
    line-height: 26px;
    @apply pb-2;
    @media(min-width: 1024px) {
      @apply text-2xl;
      line-height: 32px;
    }
  }
</style>
