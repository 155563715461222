<script>
  export default {
    name: 'BuilderTextLeftAndGoogleMapRight',
    props: {
      heading: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      alignment: {
        type: Boolean,
        default: false,
      },
      showPopupButton: {
        type: Boolean,
        default: false,
      },
      phone: {
        type: String,
        default: '',
      },
      address: {
        type: String,
        default: '',
      },
      openhours: {
        type: String,
        default: '',
      },
      rightText: {
        type: String,
        default: '',
      },
      /** Address 1 */
      address1: {
        type: String,
        default: '',
      },
      /** Address 2 */
      address2: {
        type: String,
        required: false,
        default: '',
      },
      /** City */
      city: {
        type: String,
      },
      /** State */
      state: {
        type: String,
      },
      /** Zip */
      zip: {
        type: String,
      },
      paddingTop: {
        type: Boolean,
        default: false,
      },
      buttonText: {
        type: String,
        default: '',
      },
      linkToPage: {
        type: String,
        default: '',
      },
    },

    computed: {
      formattedAddress() {
        if (!this.address1 || !this.city || !this.state || !this.zip) return null
        const address1 = this.address1.replace(/\s/gi, '+')
        const address2 = this.address2 ? this.address2.replace(' ', '+') : null
        const city = this.city.replace(' ', '+')
        const state = this.state.replace(' ', '+')
        const zip = this.zip.replace(' ', '+')
        const address = address2 ? `${address1}+${address2}` : address1

        return encodeURIComponent(`${address}+${city}+${state}+${zip}`)
      },
    },
    methods: {
      showPopup(event) {
        const popup = document.querySelector('.appointmentFormPopup')
        const overlay = document.querySelector('.mainOverlay')
        const body = document.querySelector('body')
        overlay.style.display = 'block'
        popup.style.display = 'block'
        body.classList.add('overflow-y-hidden')
      },
    },
  }
</script>

<template>
  <div
    :id="heading.split(' ').join('-').toLowerCase()"
    class="flex flex-col lg:flex-row -mx-5 pb-20 lg:pb-40"
    :class="`${(alignment ? 'lg:flex-row-reverse' : '', paddingTop ? 'pt-20 lg:pt-40' : '')}`"
  >
    <div class="w-full lg:w-1/3 px-5 mb-8 lg:mb-0">
      <h2 v-if="heading" class="block mb-4 lg:mb-8">
        {{ heading }}
      </h2>
      <div v-if="text" class="w-full text-sm contentBlock" v-html="text"></div>
      <div v-if="phone" class="mb-6">
        <h4 class="block mb-2.5 uppercase font-semibold text-sm text-gray-600">Phone</h4>
        <span class="font-medium text-lg leading-5">{{ phone }}</span>
      </div>
      <div v-if="address" class="mb-5">
        <h4 class="block mb-2.5 uppercase font-semibold text-sm text-gray-600">Address</h4>
        <span class="font-medium text-lg leading-5">{{ address }}</span>
      </div>
      <div
        v-if="openhours"
        class="w-full text-sm"
        v-html="openhours"
        :class="`${linkToPage ? 'mb-6' : ''}`"
      ></div>
      <div v-if="showPopupButton" class="buttonsHolder flex flex-col">
        <a
          v-if="linkToPage"
          class="font-semibold text-sm block text-center button-height md:px-26 md:inline-block | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
          :href="linkToPage"
          >{{ buttonText }}
        </a>
        <a
          v-if="showPopupButton"
          @click.prevent="showPopup"
          class="openAppointmentPopup font-semibold text-sm block text-center border border-black button-height md:px-26 md:inline-block | bg-transparent text-black | hover:bg-black hover:text-white focus:bg-black focus:text-white uppercase tracking-wider"
          href="#"
        >
          Appointment
        </a>
      </div>
      <div v-else>
        <a
          v-if="linkToPage"
          class="font-semibold text-sm block text-center button-height md:px-26 md:inline-block | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
          :href="linkToPage"
          >{{ buttonText }}
        </a>
      </div>
    </div>
    <div class="w-full lg:w-2/3 px-5">
      <div v-if="formattedAddress" class="map-wrapper">
        <iframe
          :src="`https://www.google.com/maps?&amp;q=${formattedAddress}&amp;output=embed`"
          width="100%"
          height="100%"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div v-if="rightText" class="text-base leading-5 rightContent" v-html="rightText"></div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .buttonsHolder {
    a {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .contentBlock {
    >>> {
      p {
        margin-bottom: 19px;
      }
    }
  }

  .map-wrapper {
    @apply relative;

    height: 450px;

    >>> iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .rightContent {
    >>> {
      a {
        @apply font-medium;

        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      p {
        display: block;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
