<script>
  import { trimHtml, insertIntoLastElement } from '@/utilities/strings'
  /** The PLP listing header for categories */
  export default {
    name: 'ProductCategoryHeader',

    props: {
      /** The title/name of the category */
      title: {
        type: String,
        required: true,
      },
      firstSelect: {
        type: Object,
        default: () => ({}),
      },
      filCount: {
        type: Number,
        default: 0,
      },
      brandTitle: {
        type: Object,
        default: () => ({}),
      },
      brandSlug: {
        type: String,
        default: '',
        required: true,
      },
      isEmpty: {
        type: Boolean,
        default: false,
      },
      /** The breadcrumb of product category */
      breadCrumb: {
        type: Array,
        default: () => [],
        required: false,
      },
      /** The title/name of the parent category */
      parentTitle: {
        type: String,
        required: false,
        default: null,
      },
      /** PLP summary passed from parent template */
      summary: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        isOpen: false,
        isShowText: false,
        selectedFirst: {},
      }
    },
    mounted() {
      this.selectedFirst = this.brandTitle
      if (localStorage.getItem('brands') !== null) {
        const storage = JSON.parse(localStorage.getItem('brands'))
        if (storage.length === 1) {
          this.isShowText = true
        }
      }
    },
    watch: {
      brandTitle(item) {
        this.selectedFirst = item
      },
    },
    computed: {
      trimmedHtml() {
        return trimHtml(this.summary, { limit: 360 })
      },
      /** The trimmed HTML text with indicator toggle added inline */
      trimmedText() {
        const addedContent =
          '<span class="relative inline-block ml-1 text-gray-500 | content-clamp-toggle"> + </span>'
        return insertIntoLastElement(this.trimmedHtml.html, addedContent)
      },
      // Expanded Text
      fullText() {
        if (this.hasMore) {
          const addedContent =
            '<span class="relative inline-block ml-1 text-gray-500 | content-clamp-toggle"> - </span>'
          return insertIntoLastElement(this.summary, addedContent)
        } else {
          return this.summary
        }
      },
      hasMore() {
        return this.trimmedHtml.more
      },
      // Aria-label text
      toggleLabel() {
        return this.isOpen ? 'Collapse text' : 'Expand text'
      },
      // Format breadcrumb nav Parent > Children
      formattedBreadCrumb() {
        const breadCrumb = this.breadCrumb
        return breadCrumb.reverse()
      },
    },
    methods: {
      toggleCollapsable() {
        this.isOpen = !this.isOpen
      },
    },
  }
</script>

<template>
  <header class="pt-16 z-15 lg:pt-24 lg:pb-4 mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
    <div class="flex flex-column xl:flex-row flex-wrap">
      <div class="w-full xl:w-1/3">
        <h1 class="block w-full uppercase font-semibold mb-0">
          <span v-if="parentTitle" class="block || heading">
            <span class="break-words mb-2 block">
              <span
                v-for="(nav, index) in formattedBreadCrumb"
                :key="index"
                class="inline -mb-1 capitalize hover:text-gray-900 transition-colors text-gray-600 | lg:-mb-2"
              >
                <a :href="`/${nav.uri}`">{{ nav.title }}</a>
                <span class="inline">/&nbsp;</span>
              </span>
            </span>
            <span class="block uppercase">
              {{ title }} <br />
              {{
                filCount === 1
                  ? `
              By ${firstSelect.label}`
                  : ''
              }}
            </span>
          </span>
          <span v-else class="uppercase leading-tight">
            {{ title }} <br />
            {{
              filCount === 1
                ? `
              By ${firstSelect.label}`
                : ''
            }}
          </span>
        </h1>
      </div>
      <div
        class="w-full xl:w-2/3 pl-0 xl:pl-12"
        :class="`${parentTitle ? 'pt-6 xl:pt-10' : 'pt-6 xl:pt-2'}`"
      >
        <div class="summary" v-html="summary"></div>
        <div class="summary">
          <p v-if="filCount === 1">
            View all {{ firstSelect.label }} {{ title }} below.
            <a
              :href="'/brands/' + firstSelect.slug"
              class="font-semibold underline hover:no-underline"
              >Click here</a
            >
            for more products by <strong>{{ firstSelect.label }}</strong
            >.
          </p>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  .heading {
    max-width: 480px;
  }

  h1 {
    span {
      font-size: 60px;
      line-height: 72px;
    }

    span.break-words {
      font-size: 24px;
      line-height: 28px;

      span {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
  @media (max-width: 1320px) {
    h1 {
      span {
        font-size: 48px;
        line-height: 54px;
      }
    }
  }

  .summary {
    max-width: 1040px;
    font-size: 18px;
    line-height: 22px;

    >>> p {
      display: inline-block;
      margin-bottom: 20px;
    }
  }

  .summary-expanded {
    >>> p {
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  .summary >>> * > * {
    @apply mb-6;

    &:last-child {
      @apply mb-0;
    }
  }

  @screen md {
    .summary-expanded {
      columns: 2;
      column-gap: 20px;

      p {
        break-inside: avoid;
        page-break-inside: avoid;
      }
    }
  }

  /* purgecss end ignore */
</style>
