<script>
  /** A reusable visual tag element that can be an anchor tag if a URL is supplied, or just a span if not. Includes
   * a slot for SVG icons to be included */

  export default {
    name: 'BaseTag',

    props: {
      /** The label/name of the tag */
      label: {
        type: String,
        required: true,
      },
      /** An optional URL to make it an anchor tag */
      url: {
        type: String,
        required: false,
        default: null,
      },
      /** The color of the tag */
      color: {
        type: String,
        required: false,
        default: 'gray',
        validator: (propValue) => {
          const validProps = ['gray', 'black', 'white']
          return validProps.indexOf(propValue) > -1
        },
      },
      /** The shape of the tag */
      shape: {
        type: String,
        required: false,
        default: 'rounded',
        validator: (propValue) => {
          const validProps = ['rounded', 'corners']
          return validProps.indexOf(propValue) > -1
        },
      },
      /** The size of the tag */
      size: {
        type: String,
        required: false,
        default: 'small',
        validator: (propValue) => {
          const validProps = ['small', 'large']
          return validProps.indexOf(propValue) > -1
        },
      },
    },

    created() {
      console.log(this.label)
    },

    computed: {
      isActivelyFiltered: function () {
        return this.$store.getters['products/getUsedFilters'].some(
          (item) => item.label === this.label
        )
      },
      /** Creates the tag color class based on the color prop */
      styleColor: function () {
        if (this.color === 'black') {
          return 'tag--black'
        } else if (this.color === 'gray') {
          return 'tag--gray'
        } else if (this.label.includes('Launched')) {
          return 'black-tag'
        } else {
          return 'white-tag'
        }
      },
      /** Create the tag shape class based on the shape prop */
      styleShape: function () {
        if (this.shape === 'corners') {
          return 'tag--corners'
        } else {
          return 'tag--rounded'
        }
      },
      /** Create the tag size class based in the size prop */
      styleSize: function () {
        if (this.size === 'large') {
          return 'tag--large'
        } else {
          return 'tag--medium'
        }
      },
    },
  }
</script>

<template>
  <div class="inline-block mb-2">
    <a
      v-if="url"
      :href="url"
      class="py-1 px-2 | capitalize tracking-wider | tag"
      :class="[styleColor, styleShape, styleSize, isActivelyFiltered ? 'active' : '']"
    >
      <span>{{ label }}</span>
      <slot name="icon"></slot>
    </a>

    <span
      v-else
      class="py-1 px-2.5 | uppercase | tag bg-transparent border border-black"
      :class="[styleColor, styleShape, styleSize, isActivelyFiltered ? 'active' : '']"
    >
      <span class="leading-none font-medium text-xs">{{ label }}</span>
      <slot name="icon"></slot>
    </span>
  </div>
</template>

<style scoped lang="postcss">
  .white-tag {
    @apply bg-transparent text-black;
  }

  .black-tag {
    @apply bg-black text-white;
  }

  .active {
    @apply bg-black text-white;
  }
</style>
